import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { generateNavigation } from '../../constants/navItems';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppSelector } from '../../redux/hooks';
import { IconButton } from '../CurrentlySellingDetails/CurrentlySellingDetailsStyle';
import { MaskIcon } from '../Icons/Icons';
import DrawerOptionContainer from './DrawerOptionContainer';
import DrawerSubOptionContainer from './DrawerSubOptionContainer';
import { ContentWrapper, DrawerOption } from './NavDrawerStyle';

const drawerContent = [
  {
    icon: '/icons/solid-dashboard.svg',
    title: 'Store',
    route: '/user/selling/dashboard',
  },
  {
    icon: '/icons/solid-selling.svg',
    title: 'Games',
    route: '/user/selling/currently-selling',
  },
  {
    icon: '/icons/seller-txn.svg',
    title: 'Gift Cards',
    route: '/user/selling/transactions',
  },
  {
    icon: '/icons/solid-withdraw.svg',
    title: 'Game Points',
    route: '/user/selling/withdrawals',
  },
  {
    icon: '/icons/solid-reports.svg',
    title: 'Xbox',
    route: '/user/selling/reports',
  },
  {
    icon: '/icons/reviews-24.svg',
    title: 'PSN',
    route: '/user/selling/rating',
  },
  {
    icon: '/icons/api-settings.svg',
    title: 'Nintendo',
    route: '/user/selling/api-settings',
  },
  {
    icon: '/icons/setting-24.svg',
    title: 'Softwares',
    route: '/user/selling/surcharge',
  },
  {
    icon: '/icons/notification_setting_re.svg',
    title: 'Pre-Order',
    route: '/user/selling/notifications',
  },
];

const drawerSubContent = [
  {
    icon: '/icons/solid-dashboard.svg',
    title: 'Platform',
    route: '/user/selling/dashboard',
  },
  {
    icon: '/icons/solid-selling.svg',
    title: 'Genre',
    route: '/user/selling/currently-selling',
  },
];

interface Props {
  onClose: () => void;
  subChild?: boolean;
  onDrawerClick?: (index: any) => void;
  lastSelected?: number;
  lastSelectedChild?: number;
  onCloseAll: any;
}

export default function DrawerContent({
  onClose,
  subChild,
  onDrawerClick,
  lastSelected,
  lastSelectedChild,
  onCloseAll,
}: Props) {
  const router = useRouter();
  const { user } = useAppSelector((state) => state);
  const theme = useTheme();
  let { messages } = useLocale();

  const navigationItems = generateNavigation(messages);
  return (
    <ContentWrapper
      fromMobNav={
        router.pathname.substring(5, 14) === '/selling/' ? false : true
      }
    >
      {navigationItems?.children.map((item) => {
        return (
          <DrawerOption
            style={{
              cursor: 'pointer',
              padding: '3px',
              borderRadius: '8px',
              display: subChild ? 'none' : 'flex',
              justifyContent: 'space-between',
            }}
            key={item.id}
          >
            <Link
              passHref
              href={
                item.url && item.name !== 'store'
                  ? '/store/' + (item.name ?? '')
                  : item.name === 'store'
                  ? '/store'
                  : '/'
              }
              prefetch={false}
            >
              <a>
                <p
                  onClick={onClose}
                  style={{
                    minWidth: '200px',
                    color: '#fff',
                  }}
                >
                  {item.displayName}
                </p>
              </a>
            </Link>

            {item.subDivision && (
              <IconButton
                style={{ margin: '0 -12px 0 -12px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDrawerClick && onDrawerClick(item);
                }}
              >
                <MaskIcon
                  url="/icons/right-arrow-black.svg"
                  width="12px"
                  height="12px"
                  selected={true}
                  margin="0"
                  color="#fff"
                />
              </IconButton>
            )}
            <ul
              style={{
                display: lastSelectedChild ? 'none' : 'block',
              }}
            >
              {item?.subDivision &&
                item?.subDivision?.map((subChild) => {
                  return (
                    <>
                      <DrawerOptionContainer
                        key={subChild.idSub}
                        item={subChild}
                        indexSubChild={subChild.idSub}
                        indexSub={item.id}
                        onDrawerClick={onDrawerClick}
                        lastSelected={lastSelected}
                        onClose={onClose}
                        onCloseAll={onCloseAll}
                      />
                      <ul
                        style={{
                          display: lastSelectedChild ? 'none' : 'block',
                        }}
                      >
                        {subChild?.children &&
                          subChild?.children?.map((subChildChildren) => {
                            return (
                              <>
                                <DrawerSubOptionContainer
                                  key={subChildChildren.idSubChild}
                                  item={subChildChildren}
                                  indexSubChild={subChildChildren.idSubChild}
                                  indexSub={subChild.idSub}
                                  onDrawerClick={onDrawerClick}
                                  lastSelected={lastSelected}
                                  lastSelectedChild={lastSelectedChild}
                                  onClose={onClose}
                                  onCloseAll={onCloseAll}
                                />
                              </>
                            );
                          })}
                      </ul>
                    </>
                  );
                })}
            </ul>
          </DrawerOption>
        );
      })}

      <ul
        style={{
          display: lastSelectedChild ? 'none' : 'block',
        }}
      >
        {navigationItems?.children.map((item) => {
          return (
            item?.subDivision &&
            item?.subDivision?.map((subChild) => {
              return (
                <>
                  <DrawerOptionContainer
                    key={subChild.idSub}
                    item={subChild}
                    indexSubChild={subChild.idSub}
                    indexSub={item.id}
                    onDrawerClick={onDrawerClick}
                    lastSelected={lastSelected}
                    onClose={onClose}
                    onCloseAll={onCloseAll}
                  />
                </>
              );
            })
          );
        })}
      </ul>

      {navigationItems?.children.map((item) => {
        return item?.subDivision?.map((subChild) => {
          return (
            subChild?.children &&
            subChild?.children?.map((subChildChildren) => {
              return (
                <>
                  <DrawerSubOptionContainer
                    key={subChildChildren.idSubChild}
                    item={subChildChildren}
                    indexSubChild={subChildChildren.idSubChild}
                    indexSub={subChild.idSub}
                    onDrawerClick={onDrawerClick}
                    lastSelected={lastSelected}
                    lastSelectedChild={lastSelectedChild}
                    onClose={onClose}
                    onCloseAll={onCloseAll}
                  />
                </>
              );
            })
          );
        });
      })}
    </ContentWrapper>
  );
}
