import { useRouter } from 'next/router';
import { memo } from 'react';
import DrawerHeader from '../MUIDrawer/DrawerHeader/DrawerHeader';
import DrawerContent from './DrawerContent';
import { NavDrawerContainer } from './NavDrawerStyle';
import SellerDrawerContent from './SellerDrawerContent';

interface Props {
  onClose: () => void;
  subChild?: boolean;
  onDrawerClick?: (index: any) => void;
  onCloseAll: () => void;
  lastedSelected?: number | null;
  lastSelectedChild?: number | null;
}

const NavDrawer = ({ onClose, onDrawerClick, onCloseAll }: Props) => {
  const router = useRouter();
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NavDrawerContainer
        changeCss={
          !(
            router.pathname.substring(5, 14) === '/selling/' ||
            router.pathname.includes('/selling-accounts')
          )
        }
      >
        <DrawerHeader onClose={onClose} onCloseAll={onCloseAll} />
        {router.pathname.substring(5, 14) === '/selling/' ||
        router.pathname.includes('/selling-accounts/') ? (
          <SellerDrawerContent onClose={onClose} />
        ) : (
          <>
            {/* <DrawerContent
              onClose={onClose}
              onDrawerClick={onDrawerClick}
              onCloseAll={onCloseAll}
            /> */}
          </>
        )}
      </NavDrawerContainer>
    </div>
  );
};

export const NavSubDrawer = ({
  onClose,
  subChild,
  onCloseAll,
  onDrawerClick,
  lastedSelected,
}: Props) => {
  const router = useRouter();
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NavDrawerContainer
        changeCss={!(router.pathname.substring(5, 14) === '/selling/')}
      >
        <DrawerHeader
          onClose={onClose}
          subChild={subChild}
          onCloseAll={onCloseAll}
          lastedSelected={lastedSelected}
        />
        {router.pathname.substring(5, 14) === '/selling/' ? (
          <SellerDrawerContent onClose={onClose} />
        ) : (
          <>
            <DrawerContent
              onClose={onClose}
              subChild={subChild}
              lastSelected={lastedSelected ?? undefined}
              onDrawerClick={onDrawerClick}
              onCloseAll={onCloseAll}
            />
          </>
        )}
      </NavDrawerContainer>
    </div>
  );
};

export const NavSubDrawerChildren = ({
  onClose,
  subChild,
  onCloseAll,
  onDrawerClick,
  lastedSelected,
  lastSelectedChild,
}: Props) => {
  const router = useRouter();
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NavDrawerContainer
        changeCss={!(router.pathname.substring(5, 14) === '/selling/')}
      >
        <DrawerHeader
          onClose={onClose}
          subChild={subChild}
          onCloseAll={onCloseAll}
          lastSelectedChild={lastSelectedChild}
        />
        {router.pathname.substring(5, 14) === '/selling/' ? (
          <SellerDrawerContent onClose={onClose} />
        ) : (
          <>
            <DrawerContent
              onClose={onClose}
              onCloseAll={onCloseAll}
              subChild={subChild}
              lastSelected={lastedSelected ?? undefined}
              onDrawerClick={onDrawerClick}
              lastSelectedChild={lastSelectedChild ?? undefined}
            />
          </>
        )}
      </NavDrawerContainer>
    </div>
  );
};

export default memo(NavDrawer);
