import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const NavDrawerContainer = styled.div<{ changeCss?: boolean }>`
  background: #212121;
  width: ${(props) => (props.changeCss ? '100%' : '290px')};
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  display: none;
  height: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 80px;
  }
`;

export const NavDrawerHeader = styled.div`
  height: 50px;
  background: #f8f8f8;
  position: relative;
  display: flex;
  padding: 10px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 0 10px 0 0;
`;

export const NavDrawerContent = styled.div`
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GoBackText = styled.div`
  display: flex;
  color: #6303e3;
  padding: 10px 20px;
`;

export const NavDrawerList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 20px;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
`;

export const NavDrawerListItem = styled.li`
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.greyLight};
  justify-content: space-between;
`;

export const IconContainer = styled.div``;

export const TextContainer = styled.div``;

export const ArrowContainer = styled.div`
  /* position: absolute; */
  right: 10px;
`;

export const ListName = styled.div`
  font-family: Onest-Bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${COLORS.blackLight1};
  margin: 40px 0 0 40px;
`;

export const NavDrawerFooter = styled.div`
  /* position: absolute;
  bottom: 0; */
  padding: 0 20px 20px 20px;
  width: 100%;
  /* display: flex; */
  background: #f8f8f8;
  /* flex-direction: column; */
  border-top: 1px solid rgba(230, 230, 230, 1);
  /* box-sizing: border-box; */
  margin-top: 20px;
`;

export const NavDrawerUserSection = styled.div`
  border-bottom: 1px solid #efe5fc;
`;

export const ContentWrapper = styled.ul<{ fromMobNav?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.fromMobNav ? '22px' : '32px')};
  padding: 0 24px;
  overflow: ${(props) => (props.fromMobNav ? 'auto' : undefined)};
  margin-bottom: ${(props) => (props.fromMobNav ? '24px' : undefined)};
`;

export const ContentSubWrapper = styled.div<{ fromMobNav?: boolean }>`
  display: flex;
  flex-direction: column;
  // gap: ${(props) => (props.fromMobNav ? '22px' : undefined)};
  overflow: ${(props) => (props.fromMobNav ? 'auto' : undefined)};
  margin-bottom: ${(props) => (props.fromMobNav ? '24px' : undefined)};
`;

export const DrawerOption = styled.li<{
  active?: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  > p {
    color: ${(props) => (props.active ? '#fff' : '#808080')};
  }
`;

export const SwipeableDrawerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transition: 0.5s;
  box-sizing: border-box;
  overflow: auto;
  outline: none;
`;
