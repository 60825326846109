import { useAppSelector } from '../../../redux/hooks';
import SellerNoticeAlert from '../SellerNoticeAlert/SellerNoticeAlert';

interface Props {
  children: React.ReactNode;
  seller?: boolean;
}

function LayoutBody({ children, seller }: Props) {
  const { user } = useAppSelector((state) => state.user);

  return (
    <>
      {seller ? (
        user?.storeDetails?.disabled === 1 ? (
          <>
            <SellerNoticeAlert />
          </>
        ) : null
      ) : null}
      {children}
    </>
  );
}

export default LayoutBody;
