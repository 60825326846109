import styled from 'styled-components';
import { WIDTH } from '../../../constants/screenResolution';

export const UserLayoutWrapper = styled.div`
  min-height: calc(100vh);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  background: ${(props) => props.theme.palette.background.bg2};
`;
export const UserLayoutContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const UserLayoutBody = styled.div`
  flex: 1;
  padding: 24px;
  background: ${(props) => props.theme.palette.background.bg2};
  overflow-y: auto;
  height: calc(100vh - 74px);

  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
  }
`;
