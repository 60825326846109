import styled from 'styled-components';
import { WIDTH } from '../../../constants/screenResolution';

export const NoticeAlertWrapper = styled.div`
  padding: 0;
  max-width: 1440px;
`;

export const NoticeAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.error.dark};
  padding: 12px;
  margin: 0 0 16px;
  p {
    font: 1rem/1.25rem Onest-Medium;
    color: ${(props) => props.theme.palette.error.dark};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 16px;
    align-items: flex-start;
  }
`;
