import axios from 'axios';
import { apiConstants } from '../constants/apiConstants';

export const getNotifications = async (filters: {
  pageNumber: string | number;
  pageSize: number | string;
}) => {
  const { data } = await axios.get(
    apiConstants.sellerServer + '/notification/web?channel=web-alert',
    {
      withCredentials: true,
      params: {
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
      },
    }
  );

  return data;
};

export const viewNotification = async (notificationId: number) => {
  const { data } = await axios.patch(
    apiConstants.sellerServer + `/notification/web/${notificationId}/status`,
    {
      isNotificationRead: true,
    },
    { withCredentials: true }
  );

  return data;
};

export const getUnreadNotificationsCount = async () => {
  try {
    const { data } = await axios.get(
      apiConstants.sellerServer + `/notification/web/details`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getUnresolvedTicketsCount = async () => {
  try {
    const { data } = await axios.get(
      apiConstants.sellerServer + `/tickets/count`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};
