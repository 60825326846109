import axios from 'axios';
import { apiConstants } from '../../../constants/apiConstants';

export const getAgentsService = async () => {
  try {
    let url = `${apiConstants.sellerServer}/agents`;

    const res = await axios.get(url, {
      withCredentials: true,
    });

    if (res) {
      return res.data.agents;
    }
  } catch (err) {
    console.log('get-agents', err);
    throw err;
  }
};

export const inviteAgentService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/invite-agent`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res;
    }
  } catch (err) {
    console.log('invite-agent', err);
    throw err;
  }
};

export const resendInviteAgentService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/resend-verification-email`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res;
    }
  } catch (err) {
    console.log('resend-invite', err);
    throw err;
  }
};

export const removeAgentService = async (customerId: number) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/remove-agent`;

    let body = {
      customerId: customerId,
    };

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res;
    }
  } catch (err) {
    console.log('remove-agent', err);
    throw err;
  }
};

export const updateAgentPermissionsService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/update-agent-permission`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res;
    }
  } catch (err) {
    console.log('update-permission', err);
    throw err;
  }
};

export const updateAgentNotificationSettingsService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/update-agent-notification-setting`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res;
    }
  } catch (err) {
    console.log('update-noti', err);
    throw err;
  }
};

export const verifyEmailNotificationService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/verify-email-notification`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log('verify-email', err);
    throw err;
  }
};

export const verifyOTPandChnageEmailNotificationService = async (body: any) => {
  try {
    let url = `${apiConstants.sellerServer}/agents/verify-otp-change-notification-email`;

    const res = await axios.post(url, body, {
      withCredentials: true,
    });

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log('verify-otp', err);
    throw err;
  }
};

export const getAgentNotificationSettingsService = async () => {
  try {
    let url = `${apiConstants.sellerServer}/agents/get-agent-notification-setting`;

    const res = await axios.get(url, {
      withCredentials: true,
    });

    if (res) {
      console.log(res.data);
      return res.data;
    }
  } catch (err) {
    console.log('get-noti', err);
    throw err;
  }
};
