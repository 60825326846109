import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useState } from 'react';
import { PageLoaderContext } from '../../../contexts/PageLoaderContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { saveEventV3 } from '../../../utils/eventTracking';
import {
  getAgentNotificationSettingsService,
  getAgentsService,
  inviteAgentService,
  removeAgentService,
  resendInviteAgentService,
  updateAgentNotificationSettingsService,
  updateAgentPermissionsService,
  verifyEmailNotificationService,
  verifyOTPandChnageEmailNotificationService,
} from '../services';

export interface AgentI {
  cust_id: number;
  store_id: number;
  is_verified: number;
  permissions: string[];
  email: string;
}

interface AgentLoadingI {
  get: boolean;
  invite: boolean;
  remove: boolean;
  permissions: boolean;
  otpSending: boolean;
  otpVerifying: boolean;
}

interface NotificationSetting {
  displayName: string;
  active: boolean;
}

export interface NotificationSettings {
  [key: string]: NotificationSetting;
}

interface useAgentInterface {
  agents: AgentI[];
  getAgents: any;
  removeAgent: (customerId: number, email: string) => void;
  inviteAgent: (email: string, type: string[]) => void;
  resendInviteAgent: (email: string, custId: number) => void;
  updateAgentPermissions: (
    type: string,
    action: 'add' | 'remove',
    customerId: number
  ) => void;
  activeDrawerOption: string;
  onNext: (value: string) => void;
  onBack: (value: string) => void;
  openSettingsDrawer: boolean;
  handleSettingsDrawer: () => void;
  updateAgentNotificationSetting: (setting: string, action: string) => void;
  verifyEmailNotification: (email: string) => void;
  verifyOTPAndChangeNotificationEmail: (
    otp: number,
    email: string,
    onSuccess: any
  ) => void;
  notificationEmail: string;
  handleNotificationEmail: (email: string) => void;
  loading: AgentLoadingI;
  getAgentNotificationSettings: () => void;
  notificationSettings: NotificationSettings;
  showResend: (email: string) => boolean;
}

const useAgentInitialState = {
  agents: [],
  getAgents: () => {},
  removeAgent: () => {},
  inviteAgent: () => {},
  resendInviteAgent: () => {},
  updateAgentPermissions: () => {},
  activeDrawerOption: 'Ticket settings',
  onNext: (value: string) => {},
  onBack: (value: string) => {},
  openSettingsDrawer: false,
  handleSettingsDrawer: () => {},
  updateAgentNotificationSetting: () => {},
  verifyEmailNotification: () => {},
  verifyOTPAndChangeNotificationEmail: () => {},
  notificationEmail: '',
  handleNotificationEmail: () => {},
  getAgentNotificationSettings: () => {},
  loading: {
    get: false,
    invite: false,
    remove: false,
    permissions: false,
    otpSending: false,
    otpVerifying: false,
  },
  notificationSettings: {},
  showResend: () => false,
};

export const useAgentContext =
  createContext<useAgentInterface>(useAgentInitialState);

export const AgentContext = () => useContext(useAgentContext);

export const AgentProvider = ({ children }: any) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const router = useRouter();
  const [activeDrawerOption, setActiveDrawerOption] =
    useState<string>('Ticket settings');
  const [notificationEmail, setNotificationEmail] = useState<string>('');

  const { dispatchPageLoader } = useContext(PageLoaderContext);

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({});

  const [agents, setAgents] = useState<AgentI[]>([]);
  const [loading, setLoading] = useState<AgentLoadingI>({
    get: false,
    invite: false,
    remove: false,
    permissions: false,
    otpSending: false,
    otpVerifying: false,
  });

  const [openSettingsDrawer, setSettingsDrawer] = useState<boolean>(false);

  const handleNotificationEmail = (email: string) => {
    setNotificationEmail(email);
  };

  const handleSettingsDrawer = useCallback(() => {
    setSettingsDrawer(!openSettingsDrawer);
    setActiveDrawerOption('Ticket settings');
  }, [openSettingsDrawer]);

  const onNext = (value: string) => {
    setActiveDrawerOption(value);
  };

  const onBack = (value: string) => {
    setActiveDrawerOption(value);
  };

  const showResend = useCallback((email: string) => {
    const resendTimestamp = localStorage.getItem(`${email}-resend`);
    if (resendTimestamp) {
      const timeElapsed = Date.now() - parseInt(resendTimestamp, 10);
      if (timeElapsed < 10 * 60 * 1000) {
        // 10 minutes in milliseconds
        return false;
      }
      return true;
    }

    return false;
  }, []);

  const getAgents = async () => {
    try {
      setLoading((prev) => {
        return { ...prev, get: true };
      });
      const agentsData = await getAgentsService();

      if (agentsData) {
        setAgents(agentsData);
      }
    } catch (err) {
    } finally {
      setLoading((prev) => {
        return { ...prev, get: false };
      });
    }
  };

  const removeAgent = async (customerId: number, email: string) => {
    try {
      setLoading((prev) => {
        return { ...prev, remove: true };
      });

      saveEventV3({
        action: 'click',
        category: 'manage-agents',
        label: 'remove',
        properties: '',
        value: [email],
        from: router,
      });
      const response = await removeAgentService(customerId);

      if (response) {
        
        await getAgents();
        dispatchSnackbar({
          type: 'success',
          payload: 'agent removed successfully',
        });
        localStorage.removeItem(`${email}-resend`);
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'unable to remove agent',
      });
    } finally {
      setLoading((prev) => {
        return { ...prev, remove: false };
      });
    }
  };

  const inviteAgent = async (email: string, type: string[]) => {
    try {
      setLoading((prev) => {
        return { ...prev, invite: true };
      });

      saveEventV3({
        action: 'click',
        category: 'manage-agents',
        label: 'invite-agent',
        properties: '',
        jsonData: {
          type: type,
        },
        value: [email],
        from: router,
      });

      let body = {
        email: email,
        type: type,
      };
      const response = await inviteAgentService(body);

      if (response) {
        await getAgents();
        dispatchSnackbar({
          type: 'success',
          payload: 'agent invited successfully',
        });
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'unable to invite agent',
      });
    } finally {
      setLoading((prev) => {
        return { ...prev, invite: false };
      });
    }
  };

  const resendInviteAgent = async (email: string, custId: number) => {
    try {
      dispatchPageLoader({
        type: 'show',
      });

      saveEventV3({
        action: 'click',
        category: 'manage-agents',
        label: 'invite-resend',
        properties: '',
        value: [email],
        from: router,
      });

      let body = {
        email: email,
        customerId: custId,
      };
      const response = await resendInviteAgentService(body);

      if (response) {
        dispatchSnackbar({
          type: 'success',
          payload: 'invite resend successfully',
        });

        await localStorage.setItem(`${email}-resend`, Date.now().toString());
        getAgents();
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'unable to resend invite',
      });
    } finally {
      dispatchPageLoader({
        type: 'hide',
      });
    }
  };

  const updateAgentPermissions = async (
    type: string,
    action: 'add' | 'remove',
    customerId: number
  ) => {
    try {
      setLoading((prev) => {
        return { ...prev, permissions: true };
      });

      let body = {
        type,
        action,
        customerId,
      };
      const response = await updateAgentPermissionsService(body);

      if (response) {
        await getAgents();
        dispatchSnackbar({
          type: 'success',
          payload: 'permission changed successfully',
        });
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'unable to invite agent',
      });
    } finally {
      setLoading((prev) => {
        return { ...prev, permissions: false };
      });
    }
  };

  const updateAgentNotificationSetting = async (
    setting: string,
    action: string
  ) => {
    try {
      dispatchPageLoader({
        type: 'show',
      });

      saveEventV3({
        action: 'click',
        category: 'ticket_notifications',
        label: 'notification_type',
        properties: setting,
        value: [action],
        from: router,
      });

      let body = {
        notification_settings: setting,
        action,
      };
      const response = await updateAgentNotificationSettingsService(body);

      if (response) {
        getAgentNotificationSettings();
        dispatchSnackbar({
          type: 'success',
          payload: 'setting change successfully',
        });
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'unable to change setting',
      });
    } finally {
      dispatchPageLoader({
        type: 'hide',
      });
    }
  };

  const verifyEmailNotification = async (email: string) => {
    try {
      setLoading((prev) => {
        return { ...prev, otpSending: true };
      });

      let body = {
        notificationEmail: email,
      };
      const response = await verifyEmailNotificationService(body);

      if (response) {
        dispatchSnackbar({
          type: 'success',
          payload: 'otp sent successfully',
        });
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message || err?.message || 'failed to send otp',
      });
    } finally {
      setLoading((prev) => {
        return { ...prev, otpSending: false };
      });
    }
  };

  const verifyOTPandChangeEmailNotification = async (
    otp: number,
    email: string,
    onSuccess: any
  ) => {
    try {
      setLoading((prev) => {
        return { ...prev, otpVerifying: true };
      });

      let body = {
        otp: otp,
        notification_email: email,
      };
      const response = await verifyOTPandChnageEmailNotificationService(body);

      if (response) {
        dispatchSnackbar({
          type: 'success',
          payload: 'otp verified and notification email changed successfully',
        });
        onSuccess();
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'failed to change notification email',
      });
    } finally {
      setLoading((prev) => {
        return { ...prev, otpVerifying: false };
      });
    }
  };

  const getAgentNotificationSettings = async () => {
    try {
      const response = await getAgentNotificationSettingsService();

      if (response) {
        setNotificationSettings(response.data.emailNotifications);
      }
    } catch (err: any) {
      dispatchSnackbar({
        type: 'failure',
        payload:
          err?.response?.data?.message ||
          err?.message ||
          'failed to get agent notification settings',
      });
    } finally {
    }
  };

  return (
    <useAgentContext.Provider
      value={{
        agents: agents,
        getAgents: getAgents,
        removeAgent: removeAgent,
        inviteAgent: inviteAgent,
        resendInviteAgent: resendInviteAgent,
        updateAgentPermissions: updateAgentPermissions,
        activeDrawerOption: activeDrawerOption,
        onNext: onNext,
        onBack: onBack,
        openSettingsDrawer: openSettingsDrawer,
        handleSettingsDrawer: handleSettingsDrawer,
        updateAgentNotificationSetting: updateAgentNotificationSetting,
        verifyEmailNotification: verifyEmailNotification,
        verifyOTPAndChangeNotificationEmail:
          verifyOTPandChangeEmailNotification,
        notificationEmail: notificationEmail,
        handleNotificationEmail: handleNotificationEmail,
        loading,
        getAgentNotificationSettings,
        notificationSettings,
        showResend,
      }}
    >
      {children}
    </useAgentContext.Provider>
  );
};
