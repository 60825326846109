import styled from 'styled-components';

import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const Container = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    margin: 0;
  }
`;

export const Header = styled.header`
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    cursor: pointer;
  }
  & > h1 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;

    & > h1 {
      font: 1.25rem/1.25rem Onest-Bold;
      color: ${(props) => props.theme.palette.text.t1};
      margin: 0;
    }
  }
`;

export const CardContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 0 0rem 1px rgb(0 0 0 / 4%);
  transition: all 0.2s ease-in-out;
  /* &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transform: translateY(-0.5rem);
  } */
`;
export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CardTitle = styled.div`
  padding: 0.5rem;
  font-size: 1rem;
  font-family: Onest-SemiBold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  color: #5a5a5a;
`;
export const CardBody = styled.div`
  font-size: 1rem;
  font-family: Onest-Regular;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Span = styled.span<{ color: string; background?: string }>`
  color: ${(props) => props.color};
  background-color: ${(props) => (props.background ? props.background : '')};
  padding: 2px 10px 2px 10px;
  border-radius: 4px;
`;

export const Section1Wrapper = styled.div`
  display: grid;
  padding: 16px 0;
  grid-template-columns: 1fr 1.15fr;
  grid-gap: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
    grid-template-columns: 1fr;
  }
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s4};
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px;
    grid-template-columns: 1fr;
  }
`;
interface PriceWrapperI {
  isFocused: boolean;
  type?: 'new' | 'existing';
}

export const PriceWrapper = styled.div<PriceWrapperI>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: ${(props) =>
    props.type === 'new'
      ? props.theme.palette.background.bg4
      : props.theme.palette.background.bg1};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  border: ${(props) =>
    props.isFocused
      ? '1px solid ' + props.theme.palette.primary.main
      : '1px solid ' + props.theme.palette.border.b2};
  box-shadow: ${(props) => props.theme.shadows.s4};
  height: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px;
    grid-template-columns: 1fr;
  }
`;

export const GameTitleContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const GameDetailsContainer = styled.div`
  & h3 {
    font: 1rem/1.25rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0 0 4px;
  }
  > p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    cursor: pointer;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const GameMoreDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  padding: 16px 0 0;
  border-top: 1px solid ${(props) => props.theme.palette.divider.d1};
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProductInfo = styled.div`
  flex: 1;
  > span {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t3};
  }
  text-transform: uppercase;
  gap: 8px;
  align-items: center;
`;

export const GameDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GameImage = styled.img`
  border-radius: 8px;
  width: 108px;
  height: 152px;
  @media (max-width: ${WIDTH.tabletMax}) {
    border-radius: 8px;
    width: 90px;
    height: 125px;
  }
`;

export const GameDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  & > h2 {
    margin: 0;
    color: ${(props) => props.theme.palette.text.t1};
    font: 1.125rem/1.5rem Onest-Bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 376px;
    @media (max-width: ${WIDTH.mobileMax}) {
      margin-bottom: 8px;
    }
  }
  & > p {
    max-width: 360px;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.black};
    margin: 0;
    margin-bottom: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    & > h2 {
      font: 1rem/1.25rem Onest-Bold;
    }
  }
`;

export const DeleteBtnWrapper = styled.div`
  > button {
    background: ${(props) => props.theme.palette.background.bg1};
    text: ${(props) => props.theme.palette.text.t1};
    user-select: none;
    :hover {
      background: ${(props) => props.theme.palette.background.bg1};
      text: ${(props) => props.theme.palette.text.t1};
    }
  }
`;

export const KeyCodesContainer = styled.div`
  padding-bottom: 24px;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s4};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  @media (max-width: ${WIDTH.tabletMax}) {
    background: ${COLORS.transparent};
    box-shadow: none;
    padding: 0 16px;
  }
`;

export const KeyCodesWrapper = styled.div`
  padding: 16px 16px 0;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0;
  }
`;

export const KeyCodesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;
  > h1 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    > h1 {
      font-size: 1.25rem;
      margin: 0;
    }
  }
`;

export const KeyCodeActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const TickCheckboxInput = styled.input`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

interface CheckboxInputDivProps {
  selected: boolean;
  disabled?: boolean;
}

export const CheckboxInputDiv = styled.div<{
  disabled?: boolean;
  selected?: boolean;
}>`
  position: relative;
  width: 24px;
  height: 24px;
  background-image: ${(props) =>
    props.selected && 'url(/icons/check-white.svg)'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.background.bg3};
  border: 1.5px solid
    ${(props) =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.background.bg3};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const SelectAllLabel = styled.label`
  font-size: 14px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin-left: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 24px;
  flex-grow: 1;
`;
export const FilterButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 0px 15px;
  gap: 10px;
  cursor: pointer;
`;
export const FilterIcon = styled.span`
  background-image: url(/icons/filter.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  width: 20px;
  height: 20px;
`;
export const FilterText = styled.span`
  -webkit-touch-callout: none;
  user-select: none;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background: ${(props) => props.theme.palette.background.bg3};
  padding: 16px 0;
  height: 48px;
  border-radius: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    box-shadow: ${(props) => props.theme.shadows.s4};
    background: ${(props) => props.theme.palette.background.bg1};
    width: 100%;
  }
`;
export const ImageKey = styled.img``;

export const ProductKeyContainer = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`;

export const TableContainer = styled.div`
  margin-bottom: 24px;
  overflow-x: auto;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-bottom: 0px;
  }
`;

export const PricingContainer = styled.div`
  h1 {
    font: 1.25rem/1.5rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    margin-bottom: 24px;
  }
  // & > p {
  //   font-size: 16px;
  //   font-family: Onest-Medium;
  //   max-width: 311px;
  //   line-height: 18px;
  //   margin: 16px 0;
  // }
  > p {
    margin: 16px 0;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const InfoContainer = styled.div`
  padding: 16px;

  border-radius: 12px;
  border: 2px solid ${(props) => props.theme.palette.error.main};
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.palette.error.main};

  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 16px 16px 0 16px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 8px;
    align-items: start;
  }
`;

export const PricingInputsContainer = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 32px 24px;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 32px 24px !important;
    margin-bottom: 24px;
  }
`;

export const PriceInput = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-items: center;
  color: ${(props) => props.theme.palette.text.t1};

  & > label {
    color: ${(props) => props.theme.palette.text.t1};
    font: 0.875rem/1.125rem Onest-Bold;
    display: flex;
    align-items: center;
  }
`;

export const YouGetPrice = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  flex-direction: column;
  justify-items: center;
  border-radius: 12px;
  margin-top: -16px;
  background: linear-gradient(130.61deg, #3da2ff 0%, #416aff 100%);
  input {
    color: #000;
    background: ${(props) =>
      props.theme.mode === 'dark' ? '#ececec' : '#ffffff'};
    border-color: ${(props) =>
      props.theme.mode === 'dark' ? '#ececec' : '#ffffff'};
  }
  & > label {
    font: 0.875rem/1.125rem Onest-Bold;
    color: ${COLORS.white};
  }
`;

export const CogsPrice = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  flex-direction: column;
  justify-items: center;
  border-radius: 12px;
  margin-top: -16px;
  background: ${(props) => props.theme.palette.background.bg2};
  input {
    color: ${(props) => props.theme.palette.text.t1};
    background: ${(props) => props.theme.palette.background.bg1};
    border-color: ${(props) => props.theme.palette.divider.d1};
  }
  & > label {
    font: 0.875rem/1.125rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const ErrorMsg = styled.div`
  font: 0.875rem/1.125rem Onest-SemiBold;
  color: ${COLORS.red};
  height: 18px;
  margin: -24px 0 12px;
`;

export const DetailPageErrorMsg = styled.div`
  font: 0.875rem/1.125rem Onest-SemiBold;
  color: ${COLORS.red};
`;
export const LowestPriceValueText = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const MessageWrapperForPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 16px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
interface PriceCommentI {
  bgColor: string;
}

export const PriceComment = styled.div<PriceCommentI>`
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 24px;
  font: 0.875rem/1.125rem Onest-Bold;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  background: ${(props) => props.bgColor};
  color: ${(props) => props.theme.palette.text.t1};
`;
interface InputProps {
  disabled?: boolean;
}

export const Input = styled.input<InputProps>`
  border: none;
  outline: none;
  background: ${(props) => props.theme.palette.background.bg1};
  color: ${(props) =>
    props.disabled ? props.theme.palette.text.t2 : props.theme.palette.text.t1};
  padding: 10px;
  font-size: 0.875rem;
  font-family: Onest-Bold;
  border: 1.5px solid ${(props) => props.theme.palette.divider.d1};
  border-radius: 8px;
  width: 88px;
  height: 42px;
  &::placeholder {
    font-size: 1rem;
    font-family: Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

interface IconButton {
  disabled?: boolean;
}

export const IconButton = styled.button<IconButton>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => props.disabled && '0.5'};
`;

export const ViewHistoryText = styled.span<{ active: boolean }>`
  font: 0.875rem/1.125rem Onest-Medium;
  margin-right: 8px;
  width: 80px;
  display: inline-block;
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  color: ${(props) =>
    props.active ? props.theme.palette.text.t1 : props.theme.palette.text.t3};
  &:hover {
    text-decoration: ${(props) => (props.active ? 'underline' : '')};
  }
`;

interface KeyStatusTileI {
  bg: string;
  color: string;
}

export const KeyStatusTile = styled.div<KeyStatusTileI>`
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  font: 0.875rem/1.125rem Onest-Bold;
  width: fit-content;
`;

export const PreOrder = styled.div`
  font-size: 12px;
  font-family: Onest-Bold;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.palette.warning.main};
`;

export const Label = styled.p`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const SectionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
  max-width: 50%;
  & > div:first-of-type {
    flex-grow: 1;
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 100%;
  }
`;

export const SellingCapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s4};
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px;
    grid-template-columns: 1fr;
    margin: 16px;
  }
`;

export const NewTagContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  color: ${(props) => props.theme.palette.text.primary};
  font: 0.875rem/1.125rem Onest-Medium;
  & p:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  p {
    font: 0.75rem/1rem Onest-SemiBold;
  }
`;

export const NewTag = styled.div`
  width: fit-content;
  padding: 4px 6px;
  display: grid;
  place-content: center;
  place-items: center;
  font-size: 10px;
  line-height: 12px;
  font-family: Onest-Medium;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  background: ${(props) => props.theme.palette.primary.main};
}
`;
