import Avatar from '@mui/material/Avatar';
import { memo } from 'react';
import { COLORS } from '../../constants/colors';

function stringToColor(string: string) {
  let hash = 0;
  let i;
  if (!string) {
    return '#000';
  }

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(
  color: string,
  name: string | undefined,
  height: string,
  width: string
) {
  let namee = name;
  if (!namee) {
    namee = 'hello';
  }
  return {
    sx: {
      bgcolor: color,
      width: width,
      color: COLORS.purple,
      height: height,
      fontFamily: 'Onest-Bold',
    },
    children: `${namee.split(' ')[0][0]}`,
  };
}

interface Props {
  name: string | undefined;
  variant: 'rounded' | 'square' | 'circular';
  width: string;
  height: string;
}

const AvatarRound = (props: Props) => {
  return (
    <div>
      <Avatar
        variant={props.variant}
        style={{ textTransform: 'uppercase' }}
        // sx={{ bgcolor: '#535CE833' }}
        {...stringAvatar('#535CE833', props.name, props.height, props.width)}
      />
    </div>
  );
};

export default memo(AvatarRound);
