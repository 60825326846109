import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Header from '../../../components/Header/Header';
import { AgentProvider } from '../../../components/ManagerAccounts/hooks/useAgentContext';
import MobileHeader from '../../../components/MobileHeader/MobileHeader';
import MobileNav from '../../../components/MobileNav/MobileNav';
import { useAppSelector } from '../../../redux/hooks';
import {
  getUnreadNotificationsCount,
  getUnresolvedTicketsCount,
} from '../../../services/notifications';
import LayoutBody from '../LayoutBody/LayoutBody';
import SideNavigation from '../SideNavigation/SideNavigation';
import {
  UserLayoutBody,
  UserLayoutContainer,
  UserLayoutWrapper,
} from './UserStyles';

interface Props {
  children: ReactNode;
  seller?: boolean;
}

interface NotificationsContextI {
  unResolvedTicketsCount: number;
  setUnresolvedTicketsCount: React.Dispatch<React.SetStateAction<number>>;
  handleGetUnresolvedTicketsCount: () => void;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: React.Dispatch<React.SetStateAction<number>>;
  handleGetUnreadNotificationsCount: () => void;
}

const NotificationsContext = createContext<NotificationsContextI | null>(null);

export const useNotificationsContext = () => useContext(NotificationsContext);

function UserLayout({ children, seller }: Props) {
  const router = useRouter();
  const { user } = useAppSelector((state) => state.user);
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    useState<number>(0);
  const [unResolvedTicketsCount, setUnresolvedTicketsCount] =
    useState<number>(0);

  const handleGetUnreadNotificationsCount = useCallback(async () => {
    try {
      const data = await getUnreadNotificationsCount();

      if (!!data.data.newNotificationsCount) {
        setUnreadNotificationsCount(Number(data.data.newNotificationsCount));
      } else {
        setUnreadNotificationsCount(0);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleGetUnresolvedTicketsCount = useCallback(async () => {
    try {
      const data = await getUnresolvedTicketsCount();

      if (!!data.data.pendingTicketsCount) {
        setUnresolvedTicketsCount(Number(data.data.pendingTicketsCount));
      } else {
        setUnresolvedTicketsCount(0);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    handleGetUnreadNotificationsCount();
    handleGetUnresolvedTicketsCount();
  }, [handleGetUnreadNotificationsCount, handleGetUnresolvedTicketsCount]);

  useEffect(() => {
    let pathname = router.pathname;
    let featureFlag = user?.storeDetails?.features_allowed;

    if (pathname === '/user/selling/campaigns') {
      if (!featureFlag?.campaigns) {
        router.replace('/404');
      }
    }

    if (pathname === '/user/selling/vat') {
      if (!featureFlag?.vat) {
        router.replace('/404');
      }
    }

    if (pathname === '/user/selling/api-settings') {
      if (!featureFlag?.apiSelling) {
        router.replace('/404');
      }
    }

    if (pathname === '/user/selling/declared-stock') {
      if (!featureFlag?.declaredStock) {
        router.replace('/404');
      }
    }

    if (user && user.agentStoreDetails?.store_details.length > 0) {
      if (!user?.store_id) {
        if (
          (pathname.includes('/user/selling-accounts/') ||
            pathname.includes('/user/selling/')) &&
          pathname !== '/user/selling-accounts/tickets' &&
          pathname !== '/user/selling-accounts/ticket-logs' &&
          pathname !== '/user/selling-accounts/tickets/[ticketId]'
        ) {
          router.replace('/404');
        } else {
          const permission =
            user.agentStoreDetails?.store_details[0].permissions;

          if (pathname === '/user/selling-accounts/tickets') {
            if (!permission.includes('tickets')) {
              router.replace('/404');
            }
          }

          if (pathname === '/user/selling-accounts/ticket-logs') {
            if (!permission.includes('ticket_logs')) {
              router.replace('/404');
            }
          }
        }
      }
    } else {
      if (
        pathname === '/user/selling-accounts/tickets' ||
        pathname === '/user/selling-accounts/ticket-logs' ||
        pathname === '/user/selling-accounts/tickets/[ticketId]'
      ) {
        router.replace('/404');
      }
    }
  }, [router, user?.storeDetails?.features_allowed]);

  const memoedValue = useMemo(() => {
    return {
      unResolvedTicketsCount,
      setUnresolvedTicketsCount,
      handleGetUnresolvedTicketsCount,
      unreadNotificationsCount,
      setUnreadNotificationsCount,
      handleGetUnreadNotificationsCount,
    };
  }, [
    unResolvedTicketsCount,
    setUnresolvedTicketsCount,
    handleGetUnresolvedTicketsCount,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
    handleGetUnreadNotificationsCount,
  ]);

  return (
    <AgentProvider>
      <NotificationsContext.Provider value={memoedValue}>
        <UserLayoutWrapper>
          <Header type="user" infinteHeader />
          <MobileHeader />
          <UserLayoutContainer>
            <SideNavigation />
            <UserLayoutBody>
              <LayoutBody seller={seller}>{children}</LayoutBody>
            </UserLayoutBody>
          </UserLayoutContainer>
          <MobileNav />
        </UserLayoutWrapper>
      </NotificationsContext.Provider>
    </AgentProvider>
  );
}

export default memo(UserLayout);
