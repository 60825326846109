import { MessageKeyTypes } from '../Interface/MessageKeyTypes';
import { NewNavbarInterface } from '../Interface/NavbarInterface';

export const generateNavigation = (
  messages: { [key in MessageKeyTypes]: string } | undefined
): NewNavbarInterface => {
  const {
    action_msg,
    adventure_msg,
    rpg_msg,
    indie_msg,
    strategy_msg,
    tps_msg,
    simulation_msg,
    sports_msg,
    pubg_mobile_uc_msg,
    pubg_new_state_nc_msg,
    garena_free_fire_diamonds_msg,
    gta_online_cash_msg,
    riot_points_msg,
    xbox_games_msg,
    xbox_game_pass_msg,
    psn_games_msg,
    psn_gift_cards_msg,
    nintendo_cards_msg,
    nintendo_eshop_cards_msg,
    nintendo_games_msg,
    nintendo_switch_games_msg,
    nintendo_msg,
    nintendo_switch_msg,
    steam_giftcard_msg,
    steam_dlcs_msg,
    steam_games_msg,
    by_genre_msg,
    platform_msg,
    platform_products_msg,
    by_region_msg,
    platform_subscription_by_region_msg,
    ps_plus_by_region_msg,
    gift_cards_by_region_msg,
    lifestyle_cards_msg,
    egift_cards_msg,
    subscriptions_msg,
    ea_play_msg,
    ubisoft_connect_games_msg,
    gog_com_msg,
    epic_games_msg,
    xbox_live_gift_cards_us_msg,
    europe_msg,
    united_kingdom_msg,
    united_states_msg,
    global_msg,
    gift_card_msg,
    game_msg,
    pre_order_msg,
    store_msg,
    game_point_msg,
    genre_msg,
    dlcs_msg,
    steal_deals_msg,
    view_all_msg,
    plus_save_with_plus_msg,
  } = messages || {};

  return {
    name: 'Nav-items',
    displayName: '',
    children: [
      {
        name: 'store',
        displayName: store_msg ?? '',
        url: '/store',
        queryStr: '/store',
        id: 1,
        navbar: true,
      },
      {
        name: 'games',
        displayName: game_msg ?? '',
        url: '/store?productType[]=game&page=1',
        queryStr: 'productType=game',
        id: 2,
        navbar: true,
        subDivision: [
          {
            name: platform_msg ?? '',
            displayName: 'Top Platform',
            idSub: 101,
            children: [
              {
                name: 'steam-games',
                displayName: 'Steam',
                url: '/store?productType[]=game&page=1&platform[]=Steam',
                queryStr: 'productType=game&platform=Steam',
                idSubChild: 1001,
                navbar: true,
              },
              {
                name: 'fm-redeem',
                displayName: 'Fm Redeem',
                url: '/store?page=1&platform[]=FM+Redeem',
                queryStr: 'platform=FM+Redeem',
                idSubChild: 1315,
                navbar: false,
              },
              {
                name: 'rockstar-games',
                displayName: 'Rockstar',
                url: '/store?productType[]=game&page=1&platform[]=Rockstar+Games',
                queryStr: 'productType=game&platform=Rockstar+Games',
                idSubChild: 1002,
                navbar: false,
              },
              {
                name: 'ea-play-games',
                displayName: ea_play_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=EA+Play',
                queryStr: 'productType=game&platform=EA+Play',
                idSubChild: 1003,
                navbar: true,
              },
              {
                name: 'ubisoft-connect-games',
                displayName: ubisoft_connect_games_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Ubisoft+Connect',
                queryStr: 'productType=game&platform=Ubisoft+Connect',
                idSubChild: 1004,
                navbar: true,
              },
              {
                name: 'xbox-games',
                displayName: 'Xbox',
                url: '/store?productType[]=game&page=1&platform[]=Xbox+Live',
                queryStr: 'productType=game&platform=Xbox+Live',
                idSubChild: 1005,
                navbar: true,
              },
              {
                name: 'mojang-games',
                displayName: 'Mojang',
                url: '/store?productType[]=game&page=1&platform[]=Mojang',
                queryStr: 'productType=game&platform=Mojang',
                idSubChild: 1006,
                navbar: false,
              },
              {
                name: 'gog-games',
                displayName: gog_com_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=GOG+COM',
                queryStr: 'productType=game&platform=GOG+COM',
                idSubChild: 1007,
                navbar: true,
              },
              {
                name: 'nintendo-games',
                displayName: nintendo_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Nintendo',
                queryStr: 'productType=game&platform=Nintendo',
                idSubChild: 1008,
                navbar: true,
              },
              {
                name: 'psn-games',
                displayName: psn_games_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=PSN',
                queryStr: 'productType=game&platform=PSN',
                idSubChild: 1009,
                navbar: true,
              },
              {
                name: 'epic-games',
                displayName: epic_games_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Epic+Games',
                queryStr: 'productType=game&platform=Epic+Games',
                idSubChild: 1010,
                navbar: true,
              },
              {
                name: 'games',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game&page=1',
                queryStr: 'productType=game',
                idSubChild: 1011,
                navbar: true,
              },
              {
                name: 'battle-net-games',
                displayName: 'Battle.net',
                url: '/store?productType[]=game&page=1&platform[]=Battle.net',
                queryStr: 'productType=game&platform=Battle.net',
                idSubChild: 1304,
                navbar: false,
              },
              {
                name: 'ncsoft-games',
                displayName: 'NCSoft',
                url: '/store?productType[]=game&page=1&platform[]=NCSoft',
                queryStr: 'productType=game&platform=NCSoft',
                idSubChild: 1012,
                navbar: false,
              },

              {
                name: 'bethesda-games',
                displayName: 'Bethesda',
                url: '/store?productType[]=game&page=1&platform[]=Bethesda',
                queryStr: 'productType=game&platform=Bethesda',
                idSubChild: 1013,
                navbar: false,
              },

              {
                name: 'other-games',
                displayName: 'Other',
                url: '/store?productType[]=game&page=1&platform[]=Other',
                queryStr: 'productType=game&platform=Other',
                idSubChild: 1014,
                navbar: false,
              },
            ],
          },
          {
            name: genre_msg ?? '',
            displayName: 'Genre',
            idSub: 102,
            children: [
              {
                name: 'action-games',
                displayName: action_msg ?? '',
                url: '/store?genre[]=Action&page=1',
                queryStr: 'productType=game&genre=Action',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'adventure-games',
                displayName: adventure_msg ?? '',
                url: '/store?page=1&genre[]=Adventure',
                queryStr: 'productType=game&genre=Adventure',
                idSubChild: 1016,
                navbar: true,
              },
              {
                name: 'rpg-games',
                displayName: rpg_msg ?? '',
                url: '/store?page=1&genre[]=RPG',
                queryStr: 'productType=game&genre=RPG',
                idSubChild: 1017,
                navbar: true,
              },

              {
                name: 'indie-games',
                displayName: indie_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Indie',
                queryStr: 'productType=game&genre=Indie',
                idSubChild: 1018,
                navbar: true,
              },

              {
                name: 'strategy-games',
                displayName: strategy_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Strategy',
                queryStr: 'productType=game&genre=Strategy',
                idSubChild: 1019,
                navbar: true,
              },
              {
                name: 'tps-games',
                displayName: tps_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=TPS',
                queryStr: 'productType=game&genre=TPS',
                idSubChild: 1020,
                navbar: true,
              },
              {
                name: 'simulation-games',
                displayName: simulation_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Simulation',
                queryStr: 'productType=game&genre=Simulation',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'sports-games',
                displayName: sports_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Sports',
                queryStr: 'productType=game&genre=Sports',
                idSubChild: 1022,
                navbar: true,
              },

              {
                name: 'multiplayer-games',
                displayName: 'Massively Multiplayer',
                url: '/store?productType[]=game&page=1&genre[]=Massively+Multiplayer',
                queryStr: 'productType=game&genre=Massively+Multiplayer',
                idSubChild: 1023,
                navbar: false,
              },
              {
                name: 'games',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game&page=1',
                queryStr: 'productType=game',
                idSubChild: 1024,
                navbar: true,
              },

              // {
              //   name: 'fighting-games',
              //   displayName: 'Fighting',
              //   url: '/store?productType[]=game&page=1&genre[]=Fighting',
              //   queryStr: 'productType=game&genre=Fighting',
              //   idSubChild: 1024,
              // },
              {
                name: 'fps-games',
                displayName: 'FPS',
                url: '/store?page=1&genre[]=FPS',
                queryStr: 'genre=FPS',
                idSubChild: 1025,
                navbar: false,
              },

              {
                name: 'hack-and-slash-games',
                displayName: 'Hack & Slash',
                url: '/store?productType[]=game&page=1&genre[]=Hack+and+Slash',
                queryStr: 'productType=game&genre=Hack+and+Slash',
                idSubChild: 1026,
                navbar: false,
              },

              // {
              //   name: 'mmo-games',
              //   displayName: 'MMO',
              //   url: '/store?productType[]=game&page=1&genre[]=MMO',
              //   queryStr: 'productType=game&genre=MMO',
              //   idSubChild: 1027,
              // },
              // {
              //   name: 'music-games',
              //   displayName: 'Music/Soundtrack',
              //   url: '/store?productType[]=game&page=1&genre[]=Music+/+Soundtrack',
              //   queryStr: 'productType=game&genre=Music+/+Soundtrack',
              //   idSubChild: 1028,
              // },
              // {
              //   name: 'platformer-games',
              //   displayName: 'Platformer',
              //   url: '/store?productType[]=game&page=1&genre[]=Platformer',
              //   queryStr: 'productType=game&genre=Platformer',
              //   idSubChild: 1029,
              // },
              // {
              //   name: 'point-and-click-games',
              //   displayName: 'Point & Click',
              //   url: '/store?productType[]=game&page=1&genre[]=Point+&+click',
              //   queryStr: 'productType=game&genre=Point+&+click',
              //   idSubChild: 1030,
              // },
              // {
              //   name: 'puzzle-games',
              //   displayName: 'Puzzle',
              //   url: '/store?genre[]=Puzzle&productType[]=game&page=1',
              //   queryStr: 'genre=Puzzle&productType=game',
              //   idSubChild: 1031,
              // },
              // {
              //   name: 'racing-games',
              //   displayName: 'Racing',
              //   url: '/store?productType[]=game&page=1&genre[]=Racing',
              //   queryStr: 'productType=game&genre=Racing',
              //   idSubChild: 1032,
              // },
            ],
          },
          {
            name: dlcs_msg ?? '',
            displayName: 'DLC',
            idSub: 103,
            children: [
              {
                name: 'call-of-duty-dlc',
                displayName: 'Call of Duty',
                url: '/store?q=call+of+duty&productType%5B%5D=dlc&page=1',
                queryStr: 'q=call+of+duty&productType%5B%5D=dlc',
                idSubChild: 10301,
                navbar: true,
              },
              {
                name: 'fortnite-dlc',
                displayName: 'Fortnite',
                url: '/store?q=fortnite&productType%5B%5D=dlc&page=1',
                queryStr: 'q=fortnite&productType%5B%5D=dlc',
                idSubChild: 10302,
                navbar: true,
              },
              {
                name: 'the-sims-dlc',
                displayName: 'The Sims',
                url: '/store?q=the+sims&productType%5B%5D=dlc&page=1',
                queryStr: 'q=the+sims&productType%5B%5D=dlc',
                idSubChild: 10303,
                navbar: true,
              },
              {
                name: 'destiny-2-dlc',
                displayName: 'Destiny 2',
                url: '/store?q=destiny+2&productType%5B%5D=dlc&page=1',
                queryStr: 'q=destiny+2&productType%5B%5D=dlc',
                idSubChild: 10304,
                navbar: true,
              },
              {
                name: 'monster-hunter-dlc',
                displayName: 'Monster Hunter',
                url: '/store?q=monster+hunter&productType%5B%5D=dlc&page=1',
                queryStr: 'q=monster+hunter&productType%5B%5D=dlc',
                idSubChild: 10305,
                navbar: true,
              },
              {
                name: 'house-flipper',
                displayName: 'House Flipper',
                url: '/store?q=house+flipper&productType%5B%5D=dlc&page=1',
                queryStr: 'q=house+flipper&productType%5B%5D=dlc',
                idSubChild: 10306,
                navbar: true,
              },
              {
                name: 'planet-zoo-dlc',
                displayName: 'Planet Zoo',
                url: '/store?q=planet+zoo&productType%5B%5D=dlc&page=1',
                queryStr: 'q=planet+zoo&productType%5B%5D=dlc',
                idSubChild: 10307,
                navbar: true,
              },
              {
                name: 'age-of-empires-dlc',
                displayName: 'Age of Empires',
                url: '/store?q=age+of+empires&productType%5B%5D=dlc&page=1',
                queryStr: 'q=age+of+empires&productType%5B%5D=dlc',
                idSubChild: 10308,
                navbar: true,
              },
              {
                name: 'dlc',
                displayName: view_all_msg ?? '',
                url: '/store?productType=dlc&page=1',
                queryStr: 'productType=dlc',
                idSubChild: 10309,
                navbar: true,
              },
              // {
              //   name: 'warhammer-dlc',
              //   displayName: 'Warhammer',
              //   url: '/store?q=warhammer&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=warhammer&productType%5B%5D=dlc',
              //   idSubChild: 1041,
              // },
              // {
              //   name: 'risk-of-rain-dlc',
              //   displayName: 'Risk of rain',
              //   url: '/store?q=risk+of+rain&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=risk+of+rain&productType%5B%5D=dlc',
              //   idSubChild: 1042,
              // },
              // {
              //   name: 'the-hunter-dlc',
              //   displayName: 'The Hunter',
              //   url: '/store?q=the+hunter&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=the+hunter&productType%5B%5D=dlc',
              //   idSubChild: 1043,
              // },
            ],
          },
          // {
          //   name: 'COLLECTIONS',
          //   displayName: 'Collections',
          //   idSub: 102,
          //   children: [
          //     {
          //       name: 'action-games',
          //       displayName: 'Action',
          //       url: '/store?genre[]=Action&page=1',
          //       queryStr: 'genre=Action',
          //       idSubChild: 1015,
          //     },

          //   ],
          // },
          {
            name: game_point_msg ?? '',
            displayName: 'Game Point',
            idSub: 104,
            children: [
              {
                name: 'pubg-mobile',
                displayName: pubg_mobile_uc_msg ?? '',
                url: '/store?q=pubg+mobile&productType%5B%5D=game+point&page=1',
                queryStr: 'q=pubg+mobile&productType%5B%5D=game+point',
                idSubChild: 1033,
                navbar: true,
              },

              {
                name: 'garena-free-fire-diamonds',
                displayName: garena_free_fire_diamonds_msg ?? '',
                url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1',
                queryStr:
                  'q=gareena+free+fire+diamonds&productType%5B%5D=game+point',
                idSubChild: 1034,
                navbar: true,
              },
              {
                name: 'fortnite-gamepoints',
                displayName: 'Fortnite',
                url: '/store?q=fortnite&productType%5B%5D=game+point&page=1',
                queryStr: 'q=fortnite&productType%5B%5D=game+point',
                idSubChild: 1035,
                navbar: true,
              },
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'EA SPORTS FC 24',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1',
                queryStr: 'q=ea+sports+fc+24&productType%5B%5D=game+point',
                idSubChild: 1036,
                navbar: true,
              },
              // {
              //   name: 'fifa-fut-points',
              //   displayName: 'FIFA FUT Points',
              //   url: '/store?q=fifa+fut+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+fut+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              // },
              {
                name: 'pubg-new-state-nc-gamepoints',
                displayName: pubg_new_state_nc_msg ?? '',
                url: '/store?q=pubg+new+state+nc&productType%5B%5D=game+point&page=1',
                queryStr: 'q=pubg+new+state+nc&productType%5B%5D=game+point',
                idSubChild: 1037,
                navbar: true,
              },
              // {
              //   name: 'gta-online-cash',
              //   displayName: 'GTA Online Cash',
              //   url: '/store?q=gta+online+cash&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=gta+online+cash&productType%5B%5D=game+point',
              //   idSubChild: 1041,
              // },
              {
                name: 'gta-online-gamepoints',
                displayName: gta_online_cash_msg ?? '',
                url: '/store?q=grand+theft+auto+online&productType%5B%5D=game+point&page=1',
                queryStr:
                  'q=grand+theft+auto+online&productType%5B%5D=game+point',
                idSubChild: 1038,
                navbar: true,
              },
              // {
              //   name: 'pubg-mobile-uc',
              //   displayName: 'PUBG Mobile UC',
              //   url: '/store?q=pubg+mobile+uc&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=pubg+mobile+uc&productType%5B%5D=game+point',
              //   idSubChild: 1042,
              // },
              {
                name: 'riot-points',
                displayName: riot_points_msg ?? '',
                url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
                queryStr: 'q=riot+points&productType%5B%5D=game+point',
                idSubChild: 1039,
                navbar: true,
              },
              {
                name: 'apex-legends-gamepoints',
                displayName: 'Apex Legends',
                url: '/store?q=apex+legends&productType%5B%5D=game+point&page=1',
                queryStr: 'q=apex+legends&productType%5B%5D=game+point',
                idSubChild: 1040,
                navbar: true,
              },
              {
                name: 'gamepoints',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game+point&page=1',
                queryStr: 'productType=game+point',
                idSubChild: 1041,
                navbar: true,
              },
              // {
              //   name: 'fifa-23-gamepoints',
              //   displayName: 'FIFA 23',
              //   url: '/store?q=fifa+23&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+23&productType%5B%5D=game+point',
              //   idSubChild: 1052,
              // },
              // {
              //   name: 'valorant-gamepoints',
              //   displayName: 'Valorant',
              //   url: '/store?q=valorant&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=valorant&productType%5B%5D=game+point',
              //   idSubChild: 1053,
              // },
              // {
              //   name: 'mobile-legends-gamepoints',
              //   displayName: 'Mobile Legends',
              //   url: '/store?q=mobile+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=mobile+legends&productType%5B%5D=game+point',
              //   idSubChild: 1054,
              // },
              // {
              //   name: 'minecraft-gamepoints',
              //   displayName: 'Minecraft',
              //   url: '/store?q=minecraft&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=minecraft&productType%5B%5D=game+point',
              //   idSubChild: 1055,
              // },
              // {
              //   name: 'league-of-legends-gamepoints',
              //   displayName: 'League Of Legends',
              //   url: '/store?q=league+of+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=league+of+legends&productType%5B%5D=game+point',
              //   idSubChild: 1056,
              // },
            ],
          },
          // {
          //   name: 'PRICE RANGE',
          //   displayName: 'Price Range',
          //   idSub: 102,
          //   children: [
          //     {
          //       name: 'action-games',
          //       displayName: 'Action',
          //       url: '/store?genre[]=Action&page=1',
          //       queryStr: 'genre=Action',
          //       idSubChild: 1015,
          //     },

          //   ],
          // }
        ],
      },
      {
        name: 'gift-cards',
        displayName: gift_card_msg ?? '',
        url: '/store?page=1&productType[]=giftcard',
        queryStr: 'productType=giftcard',
        id: 3,
        subDivision: [
          {
            name: platform_msg ?? '',
            displayName: 'Gaming Gift Cards',
            idSub: 105,
            children: [
              {
                name: 'psn-gift-cards',
                displayName: 'PSN',
                url: '/store?productType[]=giftcard&page=1&platform[]=PSN',
                queryStr: 'productType=giftcard&platform=PSN',
                idSubChild: 1042,
                navbar: true,
              },
              // {
              //   name: 'steam-giftcards',
              //   displayName: 'Steam',
              //   url: '/store?productType[]=giftcard&page=1&platform[]=Steam',
              //   queryStr: 'productType=giftcard&platform=Steam',
              //   idSubChild: 1043,
              //   navbar: true,
              // },
              {
                name: 'steam-gift-cards',
                displayName: 'Steam',
                url: '/store?productType[]=giftcard&page=1&platform[]=Steam',
                queryStr: 'productType=giftcard&platform=Steam',
                idSubChild: 1043,
                navbar: true,
              },
              {
                name: 'roblox-gift-cards',
                displayName: 'Roblox',
                url: '/store?productType[]=giftcard&page=1&platform[]=Roblox',
                queryStr: 'productType=giftcard&platform=Roblox',
                idSubChild: 1044,
                navbar: true,
              },
              // {
              //   name: 'nintendo-giftcards',
              //   displayName: 'Nintendo',
              //   url: '/store?productType[]=giftcard&page=1&platform[]=Ninetendo',
              //   queryStr: 'productType=giftcard&platform=Nintendo',
              //   idSubChild: 1045,
              //   navbar: true,
              // },
              {
                name: 'nintendo-gift-cards',
                displayName: 'Nintendo',
                url: '/store?productType[]=giftcard&page=1&platform[]=Ninetendo',
                queryStr: 'productType=giftcard&platform=Nintendo',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards',
                displayName: 'Xbox',
                url: '/store?productType[]=giftcard&page=1&platform[]=Xbox+Live',
                queryStr: 'productType=giftcard&platform=Xbox+Live',
                idSubChild: 1046,
                navbar: true,
              },
              // {
              //   name: 'blizzard-gift-cards',
              //   displayName: 'Blizzard',
              //   url: '/store?productType[]=giftcard&page=1&platform[]=Battle.net',
              //   queryStr: 'productType=giftcard&platform=Battle.net',
              //   idSubChild: 1047,
              //   navbar: true,
              // },
              {
                name: 'blizzard-gift-cards',
                displayName: 'Blizzard',
                url: '/store?productType[]=giftcard&page=1&platform[]=Battle.net',
                queryStr: 'productType=giftcard&platform=Battle.net',
                idSubChild: 1047,
                navbar: true,
              },
              {
                name: 'ea-play-gift-cards',
                displayName: 'EA Play',
                url: '/store?productType[]=giftcard&page=1&platform[]=EA+Play',
                queryStr: 'productType=giftcard&platform=EA+Play',
                idSubChild: 1048,
                navbar: true,
              },
              {
                name: 'riot-access-gift-cards',
                displayName: 'Riot Access',
                url: '/store?q=riot+access&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=riot+access&productType%5B%5D=giftcard',
                idSubChild: 1059,
                navbar: true,
              },
              // {
              //   name: 'razar-gold-giftcards',
              //   displayName: 'Razor Gold',
              //   url: '/store?q=razor+gold&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=razor+gold&productType%5B%5D=giftcard',
              //   idSubChild: 1049,
              // },
              // {
              //   name: 'blizzard-giftcards',
              //   displayName: 'Blizzard',
              //   url: '/store?q=blizzard&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=blizzard&productType%5B%5D=giftcard',
              //   idSubChild: 1064,
              // },
              {
                name: 'gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?productType=giftcard',
                queryStr: 'productType=giftcard',
                idSubChild: 1050,
                navbar: true,
              },
              // {
              //   name: 'valorant-giftcards',
              //   displayName: 'Valorant',
              //   url: '/store?q=valorant&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=valorant&productType%5B%5D=giftcard',
              //   idSubChild: 1065,
              // },
              // {
              //   name: 'world-of-warcraft-giftcards',
              //   displayName: 'World of Warcraft',
              //   url: '/store?q=world+of+warcraft&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=world+of+warcraft&productType%5B%5D=giftcard',
              //   idSubChild: 1066,
              // },

              // {
              //   name: 'diablo-giftcards',
              //   displayName: 'Diablo IV',
              //   url: '/store?q=diablo+iv&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=diablo+iv&productType%5B%5D=giftcard',
              //   idSubChild: 1068,
              // },
              // {
              //   name: 'go-cash-giftcards',
              //   displayName: 'Go Cash Game Cards',
              //   url: '/store?q=go+cash+game+card&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=go+cash+game+card&productType%5B%5D=giftcard',
              //   idSubChild: 1069,
              // },
              // {
              //   name: 'other-cards',
              //   displayName: 'Other Cards',
              //   url: '/store?productType[]=giftcard&page=1&platform[]=Other',
              //   queryStr: 'productType=giftcard&platform=Other',
              //   idSubChild: 1051,
              // },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'e-Gift Cards',
            idSub: 106,
            children: [
              {
                name: 'binance-gift-cards',
                displayName: 'Binance',
                url: '/store?q=binance&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Binance&productType%5B%5D=giftcard',
                idSubChild: 1051,
                navbar: true,
              },
              {
                name: 'netflix-gift-cards',
                displayName: 'Netflix',
                url: '/store?q=netflix&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Netflix&productType%5B%5D=giftcard',
                idSubChild: 1052,
                navbar: true,
              },
              // {
              //   name: 'google-play-giftcards',
              //   displayName: 'Google Play',
              //   url: '/store?q=google+play&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Google+Play&productType%5B%5D=giftcard',
              //   idSubChild: 1053,
              //   navbar: true,
              // },
              {
                name: 'google-play-gift-cards',
                displayName: 'Google Play',
                url: '/store?q=google+play&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Google+Play&productType%5B%5D=giftcard',
                idSubChild: 1053,
                navbar: true,
              },
              {
                name: 'amazon-gift-cards',
                displayName: 'Amazon',
                url: '/store?q=amazon&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Amazon&productType%5B%5D=giftcard',
                idSubChild: 1054,
                navbar: true,
              },
              {
                name: 'bigo-live-gift-cards',
                displayName: 'Bigo Live',
                url: '/store?q=bigo+live&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Bigo+Live&productType%5B%5D=giftcard',
                idSubChild: 1055,
                navbar: true,
              },
              {
                name: 'apple-itunes-gift-cards',
                displayName: 'Apple iTunes',
                url: '/store?q=apple+itunes&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Apple&productType%5B%5D=giftcard',
                idSubChild: 1056,
                navbar: true,
              },
              {
                name: 'jeton-cash-gift-cards',
                displayName: 'Jeton Cash',
                url: '/store?q=jeton+cash&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=jeton+cash&productType%5B%5D=giftcard',
                idSubChild: 1057,
                navbar: true,
              },
              {
                name: 'neosurf-gift-cards',
                displayName: 'Neosurf',
                url: '/store?q=neosurf&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Neosurf&productType%5B%5D=giftcard',
                idSubChild: 1058,
                navbar: true,
              },
              {
                name: 'gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?productType=giftcard',
                queryStr: 'productType=giftcard',
                idSubChild: 1050,
                navbar: true,
              },
              // {
              //   name: 'cashlib-giftcards',
              //   displayName: 'Cashlib',
              //   url: '/store?q=cashlib&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=cashlib&productType%5B%5D=giftcard',
              //   idSubChild: 1059,
              // },
              // {
              //   name: 'astropay-giftcards',
              //   displayName: 'AstroPay',
              //   url: '/store?q=astropay&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=astropay&productType%5B%5D=giftcard',
              //   idSubChild: 1080,
              // },
              // {
              //   name: 'bitcoin-crypto-giftcards',
              //   displayName: 'Bitcoin Crypto Voucher',
              //   url: '/store?q=crypto+voucher+bitcoin&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=crypto+voucher+bitcoin&productType%5B%5D=giftcard',
              //   idSubChild: 1081,
              // },
            ],
          },
          {
            name: egift_cards_msg ?? '',
            displayName: 'eGift Cards',
            idSub: 107,
            children: [
              {
                name: 'nike-gift-cards',
                displayName: 'Nike',
                url: '/store?q=nike&productType%5B%5D=giftcard&page=1',
                queryStr: 'platform=Nike&productType%5B%5D=giftcard',
                idSubChild: 1060,
                navbar: true,
              },
              {
                name: 'adidas-gift-cards',
                displayName: 'Adidas',
                url: '/store?q=adidas&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=adidas&productType%5B%5D=giftcard',
                idSubChild: 1061,
                navbar: true,
              },
              {
                name: 'h&m-gift-cards',
                displayName: 'H&M',
                url: '/store?q=h&m&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=h%26m&productType%5B%5D=giftcard',
                idSubChild: 1062,
                navbar: true,
              },
              {
                name: 'old-navy-gift-cards',
                displayName: 'Old Navy',
                url: '/store?q=old+navy&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=old+navy&productType%5B%5D=giftcard',
                idSubChild: 1063,
                navbar: true,
              },
              {
                name: 'columbia-sportswear-gift-cards',
                displayName: 'Columbia Sportswear',
                url: '/store?q=columbia+sportswear&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=columbia+sportswear&productType%5B%5D=giftcard',
                idSubChild: 1064,
                navbar: true,
              },
              {
                name: 'ikea-gift-cards',
                displayName: 'Ikea',
                url: '/store?q=ikea&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=ikea&productType%5B%5D=giftcard',
                idSubChild: 1065,
                navbar: true,
              },
              {
                name: 'zalando-gift-cards',
                displayName: 'Zalando',
                url: '/store?q=zalando&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=zalando&productType%5B%5D=giftcard',
                idSubChild: 1066,
                navbar: true,
              },
              // {
              //   name: 'zalando-giftcards',
              //   displayName: 'Zalando',
              //   url: '/store?q=zalando&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=zalando&productType%5B%5D=giftcard',
              //   idSubChild: 1066,
              //   navbar: true,
              // },
              {
                name: 'airbnb-gift-cards',
                displayName: 'Airbnb',
                url: '/store?q=airbnb&productType%5B%5D=giftcard&page=1',
                queryStr: 'q=airbnb&productType%5B%5D=giftcard',
                idSubChild: 1067,
                navbar: true,
              },
              {
                name: 'gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?productType=giftcard',
                queryStr: 'productType=giftcard',
                idSubChild: 1050,
                navbar: true,
              },
              // {
              //   name: 'walmart-giftcards',
              //   displayName: 'Walmart',
              //   url: '/store?q=walmart&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=walmart&productType%5B%5D=giftcard',
              //   idSubChild: 1068,
              // },
              // {
              //   name: 'decathlon-giftcards',
              //   displayName: 'Decathlon Gift Cards',
              //   url: '/store?q=decathlon&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=decathlon&productType%5B%5D=giftcard',
              //   idSubChild: 1091,
              // },
              // {
              //   name: 'starbucks-giftcards',
              //   displayName: 'Starbucks Gift Cards',
              //   url: '/store?q=starbucks&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=starbucks&productType%5B%5D=giftcard',
              //   idSubChild: 1092,
              // },
            ],
          },
          {
            name: subscriptions_msg ?? '',
            displayName: 'Subscriptions',
            idSub: 108,
            children: [
              {
                name: 'xbox-game-pass',
                displayName: xbox_game_pass_msg ?? '',
                url: '/store?q=xbox+game+pass&productType%5B%5D=subscription&page=1',
                queryStr: 'q=xbox+game+pass&productType%5B%5D=subscription',
                idSubChild: 1069,
                navbar: true,
              },
              {
                name: 'ea-play',
                displayName: 'EA Play',
                url: '/store?q=ea+play&productType%5B%5D=subscription&page=1',
                queryStr: 'platform=EA+Play&productType%5B%5D=subscription',
                idSubChild: 1070,
                navbar: true,
              },
              {
                name: 'Nintendo Switch Online',
                displayName: 'Nintendo',
                url: '/store?q=nitendo+switch+online&productType%5B%5D=subscription&page=1',
                queryStr:
                  'q=nitendo+switch+online&productType%5B%5D=subscription',
                idSubChild: 1071,
                navbar: true,
              },
              {
                name: 'discord-nitro',
                displayName: 'Discord Nitro',
                url: '/store?q=discord+nitro&productType%5B%5D=subscription&page=1',
                queryStr: 'q=discord+nitro&productType%5B%5D=subscription',
                idSubChild: 1072,
                navbar: true,
              },
              {
                name: 'tinder-gold',
                displayName: 'Tinder Gold',
                url: '/store?q=tinder+gold&productType%5B%5D=subscription&page=1',
                queryStr: 'q=tinder+gold&productType%5B%5D=subscription',
                idSubChild: 1073,
                navbar: true,
              },
              {
                name: 'spotify',
                displayName: 'Spotify',
                url: '/store?q=spotify&productType%5B%5D=subscription&page=1',
                queryStr: 'q=spotify&productType%5B%5D=subscription',
                idSubChild: 1074,
                navbar: true,
              },
              {
                name: 'playstation-plus',
                displayName: 'Playstation Plus',
                url: '/store?q=playstation+plus&productType%5B%5D=subscription&page=1',
                queryStr: 'q=playstation+plus&productType%5B%5D=subscription',
                idSubChild: 1075,
                navbar: true,
              },
              {
                name: 'subscription',
                displayName: view_all_msg ?? '',
                url: '/store?productType=subscription&page=1',
                queryStr: 'productType=subscription',
                idSubChild: 1076,
                navbar: true,
              },

              // {
              //   name: 'canva-pro',
              //   displayName: 'Canva Pro',
              //   url: '/store?q=canva+pro&productType%5B%5D=subscription&page=1',
              //   queryStr: 'q=canva+pro&productType%5B%5D=subscription',
              //   idSubChild: 1100,
              // },
              // {
              //   name: 'noping+game+tunnel',
              //   displayName: 'NoPing Game Tunnel',
              //   url: '/store?q=noping+game+tunnel&productType%5B%5D=subscription&page=1',
              //   queryStr: 'q=noping+game+tunnel&productType%5B%5D=subscription',
              //   idSubChild: 1101,
              // },
              // {
              //   name: 'nord+vpn',
              //   displayName: 'Nord Vpn',
              //   url: '/store?q=nord+vpn&productType%5B%5D=subscription&page=1',
              //   queryStr: 'q=nord+vpn&productType%5B%5D=subscription',
              //   idSubChild: 1102,
              // },
              // {
              //   name: 'youtube+premium+trial',
              //   displayName: 'Youtube Premium Trial',
              //   url: '/store?q=youtube+premium+trial&productType%5B%5D=subscription&page=1',
              //   queryStr:
              //     'q=youtube+premium+trial&productType%5B%5D=subscription',
              //   idSubChild: 1103,
              // },
              // {
              //   name: 'crunchyroll+premium',
              //   displayName: 'Crunchyroll Premium',
              //   url: '/store?q=crunchyroll+premium&productType%5B%5D=subscription&page=1',
              //   queryStr: 'q=crunchyroll+premium&productType%5B%5D=subscription',
              //   idSubChild: 1104,
              // },
              // {
              //   name: 'midjourney',
              //   displayName: 'Midjourney',
              //   url: '/store?q=midjourney&productType%5B%5D=subscription&page=1',
              //   queryStr: 'q=midjourney&productType%5B%5D=subscription',
              //   idSubChild: 1105,
              // },
            ],
          },

          {
            name: by_region_msg ?? '',
            displayName: 'By Region',
            idSub: 109,
            children: [
              {
                name: 'gift-cards-global',
                displayName: global_msg ?? '',
                url: '/store?productType=giftcard&page=1&region=3&worksIn=false',
                queryStr: 'productType=giftcard&region=3&worksIn=false',
                idSubChild: 1077,
                navbar: true,
              },
              {
                name: 'gift-cards-europe',
                displayName: europe_msg ?? '',
                url: '/store?productType=giftcard&page=1&region=1&worksIn=false',
                queryStr: 'productType=giftcard&region=1&worksIn=false',
                idSubChild: 1078,
                navbar: true,
              },
              {
                name: 'gift-cards-united-states',
                displayName: united_states_msg ?? '',
                url: '/store?productType=giftcard&page=1&region=2&worksIn=false',
                queryStr: 'productType=giftcard&region=2&worksIn=false',
                idSubChild: 1079,
                navbar: true,
              },
              {
                name: 'gift-cards-turkey',
                displayName: 'Turkey',
                url: '/store?productType=giftcard&page=1&region=21&worksIn=false',
                queryStr: 'productType=giftcard&region=21&worksIn=false',
                idSubChild: 1080,
                navbar: true,
              },
              {
                name: 'gift-cards-poland',
                displayName: 'Poland',
                url: '/store?productType=giftcard&page=1&region=19&worksIn=false',
                queryStr: 'productType=giftcard&region=19&worksIn=false',
                idSubChild: 1081,
                navbar: true,
              },
              {
                name: 'gift-cards-germany',
                displayName: 'Germany',
                url: '/store?productType=giftcard&page=1&region=13&worksIn=false',
                queryStr: 'productType=giftcard&region=13&worksIn=false',
                idSubChild: 1082,
                navbar: true,
              },
              {
                name: 'gift-cards-united-kingdom',
                displayName: united_kingdom_msg ?? '',
                url: '/store?productType=giftcard&page=1&region=8&worksIn=false',
                queryStr: 'productType=giftcard&region=8&worksIn=false',
                idSubChild: 1083,
                navbar: true,
              },
              {
                name: 'gift-cards-india',
                displayName: 'India',
                url: '/store?productType=giftcard&page=1&region=16&worksIn=false',
                queryStr: 'productType=giftcard&region=16&worksIn=false',
                idSubChild: 1084,
                navbar: true,
              },
              {
                name: 'gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?productType=giftcard&page=1',
                queryStr: 'productType=giftcard&page=1',
                idSubChild: 1023,
                navbar: true,
              },
              // {
              //   name: 'gift-cards-brazil',
              //   displayName: 'Brazil',
              //   url: '/store?productType[]=giftcard&page=1&region[]=15',
              //   queryStr: 'productType=giftcard&region=15',
              //   idSubChild: 1085,
              // },
              // {
              //   name: 'gift-cards-france',
              //   displayName: 'France',
              //   url: '/store?productType[]=giftcard&page=1&region[]=34',
              //   queryStr: 'productType=giftcard&region=34',
              //   idSubChild: 1037,
              // },
              // {
              //   name: 'gift-cards-japan',
              //   displayName: 'Japan',
              //   url: '/store?productType[]=giftcard&page=1&region[]=17',
              //   queryStr: 'productType=giftcard&region=17',
              //   idSubChild: 1037,
              // },
              // {
              //   name: 'gift-cards-italy',
              //   displayName: 'Italy',
              //   url: '/store?productType[]=giftcard&page=1&region[]=36',
              //   queryStr: 'productType=giftcard&region=36',
              //   idSubChild: 1037,
              // },
            ],
          },

          // {
          //   name: 'priceRange',
          //   displayName: 'Price Range',
          //   idSub: 103,
          //   children: [
          //     {
          //       name: 'garena-free-fire-diamonds',
          //       displayName: 'Garena Free Fire Diamonds',
          //       url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1',
          //       queryStr:
          //         'q=gareena+free+fire+diamonds&productType%5B%5D=game+point',
          //       idSubChild: 1038,
          //     }
          //   ]
          // }
        ],
        navbar: true,
      },
      // {
      //   name: 'game-points',
      //   displayName: 'Game Points',
      //   url: '/store?page=1&productType[]=game+point',
      //   queryStr: 'productType=game+point',
      //   id: 4,

      //   subDivision: [
      //     {
      //       name: 'by platform',
      //       displayName: 'By Game',
      //       idSub: 105,
      //       children: [
      //         {
      //           name: 'garena-free-fire-diamonds',
      //           displayName: 'Garena Free Fire Diamonds',
      //           url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1',
      //           queryStr:
      //             'q=gareena+free+fire+diamonds&productType%5B%5D=game+point',
      //           idSubChild: 1038,
      //         },
      //         {
      //           name: 'fifa-fut-points',
      //           displayName: 'FIFA FUT Points',
      //           url: '/store?q=fifa+fut+points&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=fifa+fut+points&productType%5B%5D=game+point',
      //           idSubChild: 1039,
      //         },
      //         {
      //           name: 'pubg-new-state-nc',
      //           displayName: 'PUBG New State NC',
      //           url: '/store?q=pubg+new+state+nc&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=pubg+new+state+nc&productType%5B%5D=game+point',
      //           idSubChild: 1040,
      //         },
      //         {
      //           name: 'gta-online-cash',
      //           displayName: 'GTA Online Cash',
      //           url: '/store?q=gta+online+cash&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=gta+online+cash&productType%5B%5D=game+point',
      //           idSubChild: 1041,
      //         },
      //         {
      //           name: 'pubg-mobile-uc',
      //           displayName: 'PUBG Mobile UC',
      //           url: '/store?q=pubg+mobile+uc&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=pubg+mobile+uc&productType%5B%5D=game+point',
      //           idSubChild: 1042,
      //         },
      //         {
      //           name: 'riot-points',
      //           displayName: 'Riot Points',
      //           url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=riot+points&productType%5B%5D=game+point',
      //           idSubChild: 1043,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: 'steam',
        displayName: 'Steam',
        url: '/store?page=1&platform=Steam',
        queryStr: 'platform=Steam',
        id: 11,
        subDivision: [
          {
            name: platform_products_msg?.replace('[_PLATFORM_]', 'Steam') ?? '',
            displayName: 'Steam Products',
            idSub: 125,
            children: [
              {
                name: 'steam-games',
                displayName: steam_games_msg ?? '',
                url: '/store?platform[]=Steam&page=1&productType[]=game',
                queryStr: 'platform=Steam&productType=game',
                idSubChild: 12501,
                navbar: true,
              },
              {
                name: 'steam-dlc',
                displayName: steam_dlcs_msg ?? '',
                url: '/store?platform[]=Steam&page=1&productType[]=dlc',
                queryStr: 'platform=Steam&productType=dlc',
                idSubChild: 12502,
                navbar: true,
              },
              {
                name: 'steam-gift-cards',
                displayName: steam_giftcard_msg ?? '',
                url: '/store?platform[]=Steam&page=1&productType[]=giftcard',
                queryStr: 'platform=Steam&productType=giftcard',
                idSubChild: 12403,
                navbar: true,
              },
              {
                name: 'steam',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Steam',
                queryStr: 'platform=Steam',
                idSubChild: 12504,
                navbar: true,
              },
            ],
          },
          // {
          //   name: 'PSN Gift Cards',
          //   displayName: 'PSN Gift Cards',
          //   idSub: 116,
          //   children: [
          //     {
          //       name: '[pdp]playstation-store-50-eur-gift-card-germany-digital-code-p9885504',
          //       displayName: 'PlayStation Store €50 (DE)',
          //       url: '/store?q=playstation+store+€50&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€50&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1236,
          //     },
          //     {
          //       name: '[pdp]playstation-store-z100-pln-gift-card-poland-digital-code-p9884648',
          //       displayName: 'PlayStation Store zł100 (PL)',
          //       url: '/store?q=playstation+store+zł100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+zł100&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1237,
          //     },
          //     {
          //       name: '[pdp]playstation-network-card-10-us-p217573',
          //       displayName: 'PlayStation Store $10 (US)',
          //       url: '/store?q=playstation+store+$10&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+$10&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1238,
          //     },
          //     {
          //       name: '[pdp]playstation-store-100-eur-gift-card-spain-digital-code-p9885789',
          //       displayName: 'PlayStation Store €100 (ES)',
          //       url: '/store?q=playstation+store+€100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€100&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1239,
          //     },
          //     {
          //       name: '[pdp]playstation-store-15-eur-gift-card-France-digital-code-p9885524',
          //       displayName: 'PlayStation Store $15 (FR)',
          //       url: '/store?q=playstation+store+$15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+$15&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1240,
          //     },
          //     {
          //       name: '[pdp]playstation-store-4000-inr-gift-card-india-digital-code-p9885517',
          //       displayName: 'PlayStation Store ₹4000 (IN)',
          //       url: '/store?q=playstation+store+€25&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€25&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1241,
          //     },
          //     {
          //       name: '[pdp]playstation-store-40-eur-gift-card-netherlands-digital-code-p9885805',
          //       displayName: 'PlayStation Store €40 (NL))',
          //       url: '/store?q=playstation+store+€15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€15&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1242,
          //     },
          //     {
          //       name: 'psn-giftcards',
          //       displayName: 'View All',
          //       url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
          //       queryStr: 'platform=PSN&productType=giftcard',
          //       idSubChild: 1243,
          //     },
          //   ],
          // },
          // {
          //   name: 'PS Plus',
          //   displayName: 'PS Plus',
          //   idSub: 117,
          //   children: [
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-us-digital-code-p9887554',
          //       displayName: 'PlayStation Plus 3 Months (US)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1244,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-uk-digital-code-p9887558',
          //       displayName: 'PlayStation Plus 3 Months (UK)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1245,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-uk-digital-code-p9887557',
          //       displayName: 'PlayStation Plus 1 Month (UK)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1246,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-essential-12-months-membership-br-digital-code-p9893381',
          //       displayName: 'PlayStation Plus 12 Months (BR)',
          //       url: '/store?q=playstation+plus+12+months&platform[]=PSN&productType[]=subscription&page=1&region=15',
          //       queryStr:
          //         'q=playstation+plus+12+months&platform=PSN&productType=subscription&region=15',
          //       idSubChild: 1247,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-us-digital-code-p9887553',
          //       displayName: 'PlayStation Plus 1 Month (US)',
          //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1248,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-de-digital-code-p9887555',
          //       displayName: 'PlayStation Plus 1 Month (DE)',
          //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=13',
          //       queryStr:
          //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=13',
          //       idSubChild: 1249,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-de-digital-code-p9887556',
          //       displayName: 'PlayStation Plus 3 Months (DE)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=13',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=13',
          //       idSubChild: 1250,
          //     },
          //     {
          //       name: 'psn-plus',
          //       displayName: 'View All',
          //       url: '/store?platform[]=PSN&page=1&productType[]=subscription',
          //       queryStr: 'platform=PSN&productType=subscription',
          //       idSubChild: 1251,
          //     },
          //   ],
          // },
          {
            name: by_region_msg ?? '',
            displayName: 'by Region',
            idSub: 126,
            children: [
              {
                name: 'steam-global',
                displayName: 'Steam Global',
                url: '/store?platform[]=Steam&page=1&region=3&worksIn=false',
                queryStr: 'platform=Steam&region=3&worksIn=false',
                idSubChild: 12601,
                navbar: true,
              },
              {
                name: 'steam-europe',
                displayName: 'Steam Europe',
                url: '/store?platform[]=Steam&page=1&region=1&worksIn=false',
                queryStr: 'platform=Steam&region=1&worksIn=false',
                idSubChild: 12602,
                navbar: true,
              },
              {
                name: 'steam-united-states',
                displayName: 'Steam United States',
                url: '/store?platform[]=Steam&page=1&region=2&worksIn=false',
                queryStr: 'platform=Steam&region=2&worksIn=false',
                idSubChild: 12603,
                navbar: true,
              },
              {
                name: 'steam-turkey',
                displayName: 'Steam Turkey',
                url: '/store?platform=Steam&page=1&region=21&worksIn=false',
                queryStr: 'platform=Steam&region=21&worksIn=false',
                idSubChild: 12604,
                navbar: true,
              },
              {
                name: 'steam-row',
                displayName: 'Steam ROW',
                url: '/store?platform[]=Steam&page=1&region=10&worksIn=false',
                queryStr: 'platform=Steam&region=10',
                idSubChild: 12605,
                navbar: true,
              },
              {
                name: 'steam-india',
                displayName: 'Steam India',
                url: '/store?platform[]=Steam&page=1&region=16&worksIn=false',
                queryStr: 'platform=Steam&region=16&worksIn=false',
                idSubChild: 12607,
                navbar: true,
              },

              {
                name: 'steam-hong-kong',
                displayName: 'Steam Hong Kong',
                url: '/store?platform[]=Steam&page=1&region=22&worksIn=false',
                queryStr: 'platform=Steam&region=22&worksIn=false',
                idSubChild: 12608,
                navbar: true,
              },
              {
                name: 'steam-united-kingdom',
                displayName: 'Steam United Kingdom',
                url: '/store?platform[]=Steam&page=1&region=8&worksIn=false',
                queryStr: 'platform=Steam&&region=8&worksIn=false',
                idSubChild: 12609,
                navbar: true,
              },

              {
                name: 'steam',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Steam',
                queryStr: 'platform=Steam',
                idSubChild: 12610,
                navbar: true,
              },

              // {
              //   name: 'steam-greece',
              //   displayName: 'Greece',
              //   url: '/store?platform[]=Steam&productType[]=giftcard&page=1&region=49',
              //   queryStr: 'platform=Steam&productType=giftcard&region=49',
              //   idSubChild: 1259,
              // },

              // {
              //   name: 'steam-taiwan',
              //   displayName: 'Taiwan',
              //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=23',
              //   queryStr: 'platform=PSN&productType=giftcard&region=23',
              //   idSubChild: 1260,
              // },
            ],
          },
          {
            name: by_genre_msg ?? '',
            displayName: 'by Genre',
            idSub: 127,
            children: [
              {
                name: 'steam-action-games',
                displayName: action_msg ?? '',
                url: '/store?genre[]=Action&page=1',
                queryStr: 'platform=Steam&genre=Action',
                idSubChild: 12701,
                navbar: true,
              },
              {
                name: 'steam-adventure-games',
                displayName: adventure_msg ?? '',
                url: '/store?page=1&genre[]=Adventure',
                queryStr: 'platform=Steam&genre=Adventure',
                idSubChild: 12702,
                navbar: true,
              },
              {
                name: 'steam-rpg-games',
                displayName: rpg_msg ?? '',
                url: '/store?page=1&genre[]=Rpg',
                queryStr: 'platform=Steam&genre=RPG',
                idSubChild: 12703,
                navbar: true,
              },

              {
                name: 'steam-indie-games',
                displayName: indie_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Indie',
                queryStr: 'platform=Steam&genre=Indie',
                idSubChild: 12704,
                navbar: true,
              },

              {
                name: 'steam-strategy-games',
                displayName: strategy_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Strategy',
                queryStr: 'platform=Steam&genre=Strategy',
                idSubChild: 12704,
                navbar: true,
              },
              {
                name: 'steam-tps-games',
                displayName: tps_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=TPS',
                queryStr: 'platform=Steam&genre=TPS',
                idSubChild: 12705,
                navbar: true,
              },
              {
                name: 'steam-simulation-games',
                displayName: simulation_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Simulation',
                queryStr: 'platform=Steam&genre=Simulation',
                idSubChild: 12706,
                navbar: true,
              },
              {
                name: 'steam-sports-games',
                displayName: sports_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Sports',
                queryStr: 'platform=Steam&genre=Sports',
                idSubChild: 12707,
                navbar: true,
              },

              {
                name: 'steam-multiplayer-games',
                displayName: 'Massively Multiplayer',
                url: '/store?productType[]=game&page=1&genre[]=Massively+Multiplayer',
                queryStr: 'platform=Steam&genre=Massively+Multiplayer',
                idSubChild: 12708,
                navbar: false,
              },
              {
                name: 'steam-games',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Steam&productType=game',
                queryStr: 'platform=Steam&productType=game',
                idSubChild: 12709,
                navbar: true,
              },
            ],
          },
        ],
        navbar: true,
      },
      {
        name: 'psn',
        displayName: 'PSN',
        url: '/store?page=1&platform=PSN',
        queryStr: 'platform=PSN',
        id: 5,
        subDivision: [
          {
            name: platform_products_msg?.replace('[_PLATFORM_]', 'PSN') ?? '',
            displayName: 'PSN Products',
            idSub: 115,
            children: [
              {
                name: 'psn-games',
                displayName: psn_games_msg ?? '',
                url: '/store?platform[]=PSN&page=1&productType[]=game',
                queryStr: 'platform=PSN&productType=game',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'psn-dlc',
                displayName: 'PSN DLCs',
                url: '/store?platform[]=PSN&page=1&productType[]=dlc',
                queryStr: 'platform=PSN&productType=dlc',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'psn-gift-cards',
                displayName: psn_gift_cards_msg ?? '',
                url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
                queryStr: 'platform=PSN&productType=giftcard',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'psn-subsciption',
                displayName: 'PSN Subscription',
                url: '/store?platform[]=PSN&page=1&productType[]=subscription',
                queryStr: 'platform=PSN&productType=subscription',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'psn',
                displayName: view_all_msg ?? '',
                url: '/store?platform=PSN',
                queryStr: 'platform=PSN',
                idSubChild: 1111,
                navbar: true,
              },
              // {
              //   name: 'marvels-spider-man',
              //   displayName: "Marvel's Spider-Man",
              //   url: '/store?q=marvels+spider+man&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=marvels+spider+man&platform=PSN&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: '[pdp]wall-world-eu-ps5-psn-digital-code-p9891104',
              //   displayName: 'Wall World',
              //   url: '/store?q=wall+world&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=wall+world&platform=PSN&productType=game',
              //   idSubChild: 1229,
              // },
              // {
              //   name: '[pdp]ea-sports-fc-24-eu-ps5-psn-digital-code-p9887017',
              //   displayName: 'EA SPORTS FC 24',
              //   url: '/store?q=ea+sports+fc+24&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=ea+sports+fc+24&platform=PSN&productType=game',
              //   idSubChild: 1230,
              // },
              // {
              //   name: '[pdp]final-fantasy-xvi-ps5-psn-digital-code-p9884554',
              //   displayName: 'Final Fantasy XVI',
              //   url: '/store?q=final+fantasy+xvi&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=final+fantasy+xvi&platform=PSN&productType=game',
              //   idSubChild: 1231,
              // },
              // {
              //   name: '[pdp]payday-3-eu-ps5-psn-digital-code-p9892532',
              //   displayName: 'Payday 3',
              //   url: '/store?q=payday+3&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=payday+3&platform=PSN&productType=game',
              //   idSubChild: 1232,
              // },
              // {
              //   name: '[pdp]prince-of-persia-the-lost-crown-deluxe-edition-eu-ps5-psn-digital-code-p9893760',
              //   displayName: 'Prince of Persia: The Lost Crown',
              //   url: '/store?q=prince+of+persia+the+lost+crown&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr:
              //     'q=prince+of+persia+the+lost+crown&platform=PSN&productType=game',
              //   idSubChild: 1233,
              // },
              // {
              //   name: '[pdp]just-dance-2024-eu-ps5-psn-digital-code-p9887906',
              //   displayName: 'Just Dance 2024',
              //   url: '/store?q=just+dance+2024&platform[]=PSNe&page=1&productType[]=game',
              //   queryStr: 'q=just+dance+2024&platform=PSN&productType=game',
              //   idSubChild: 1234,
              // },
              // {
              //   name: 'psn-games',
              //   displayName: 'View All',
              //   url: '/store?platform[]=PSN&page=1&productType[]=game',
              //   queryStr: 'platform=PSN&productType=game',
              //   idSubChild: 1235,
              // },
            ],
          },
          // {
          //   name: 'PSN Gift Cards',
          //   displayName: 'PSN Gift Cards',
          //   idSub: 116,
          //   children: [
          //     {
          //       name: '[pdp]playstation-store-50-eur-gift-card-germany-digital-code-p9885504',
          //       displayName: 'PlayStation Store €50 (DE)',
          //       url: '/store?q=playstation+store+€50&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€50&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1236,
          //     },
          //     {
          //       name: '[pdp]playstation-store-z100-pln-gift-card-poland-digital-code-p9884648',
          //       displayName: 'PlayStation Store zł100 (PL)',
          //       url: '/store?q=playstation+store+zł100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+zł100&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1237,
          //     },
          //     {
          //       name: '[pdp]playstation-network-card-10-us-p217573',
          //       displayName: 'PlayStation Store $10 (US)',
          //       url: '/store?q=playstation+store+$10&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+$10&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1238,
          //     },
          //     {
          //       name: '[pdp]playstation-store-100-eur-gift-card-spain-digital-code-p9885789',
          //       displayName: 'PlayStation Store €100 (ES)',
          //       url: '/store?q=playstation+store+€100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€100&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1239,
          //     },
          //     {
          //       name: '[pdp]playstation-store-15-eur-gift-card-France-digital-code-p9885524',
          //       displayName: 'PlayStation Store $15 (FR)',
          //       url: '/store?q=playstation+store+$15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+$15&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1240,
          //     },
          //     {
          //       name: '[pdp]playstation-store-4000-inr-gift-card-india-digital-code-p9885517',
          //       displayName: 'PlayStation Store ₹4000 (IN)',
          //       url: '/store?q=playstation+store+€25&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€25&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1241,
          //     },
          //     {
          //       name: '[pdp]playstation-store-40-eur-gift-card-netherlands-digital-code-p9885805',
          //       displayName: 'PlayStation Store €40 (NL))',
          //       url: '/store?q=playstation+store+€15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
          //       queryStr:
          //         'q=playstation+store+€15&platform=PSN&productType=giftcard&region=13',
          //       idSubChild: 1242,
          //     },
          //     {
          //       name: 'psn-giftcards',
          //       displayName: 'View All',
          //       url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
          //       queryStr: 'platform=PSN&productType=giftcard',
          //       idSubChild: 1243,
          //     },
          //   ],
          // },
          // {
          //   name: 'PS Plus',
          //   displayName: 'PS Plus',
          //   idSub: 117,
          //   children: [
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-us-digital-code-p9887554',
          //       displayName: 'PlayStation Plus 3 Months (US)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1244,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-uk-digital-code-p9887558',
          //       displayName: 'PlayStation Plus 3 Months (UK)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1245,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-uk-digital-code-p9887557',
          //       displayName: 'PlayStation Plus 1 Month (UK)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1246,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-essential-12-months-membership-br-digital-code-p9893381',
          //       displayName: 'PlayStation Plus 12 Months (BR)',
          //       url: '/store?q=playstation+plus+12+months&platform[]=PSN&productType[]=subscription&page=1&region=15',
          //       queryStr:
          //         'q=playstation+plus+12+months&platform=PSN&productType=subscription&region=15',
          //       idSubChild: 1247,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-us-digital-code-p9887553',
          //       displayName: 'PlayStation Plus 1 Month (US)',
          //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1248,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-1-month-membership-de-digital-code-p9887555',
          //       displayName: 'PlayStation Plus 1 Month (DE)',
          //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=13',
          //       queryStr:
          //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=13',
          //       idSubChild: 1249,
          //     },
          //     {
          //       name: '[pdp]playstation-plus-3-months-membership-de-digital-code-p9887556',
          //       displayName: 'PlayStation Plus 3 Months (DE)',
          //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=13',
          //       queryStr:
          //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=13',
          //       idSubChild: 1250,
          //     },
          //     {
          //       name: 'psn-plus',
          //       displayName: 'View All',
          //       url: '/store?platform[]=PSN&page=1&productType[]=subscription',
          //       queryStr: 'platform=PSN&productType=subscription',
          //       idSubChild: 1251,
          //     },
          //   ],
          // },
          {
            name: gift_cards_by_region_msg ?? '',
            displayName: 'Gift Cards by Region',
            idSub: 118,
            children: [
              {
                name: 'psn-gift-cards-germany',
                displayName: 'PSN Giftcard Germany',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=13&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=13&worksIn=false',
                idSubChild: 1252,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-poland',
                displayName: 'PSN Giftcard Poland',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=19&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=19&worksIn=false',
                idSubChild: 1253,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-united-states',
                displayName: 'PSN Giftcard United States',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=2&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=2&worksIn=false',
                idSubChild: 1254,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-spain',
                displayName: 'PSN Giftcard Spain',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=56&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=56&worksIn=false',
                idSubChild: 1255,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-france',
                displayName: 'PSN Giftcard France',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=34&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=34&worksIn=false',
                idSubChild: 1256,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-india',
                displayName: 'PSN Giftcard India',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=16&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=16&worksIn=false',
                idSubChild: 1257,
                navbar: true,
              },
              {
                name: 'psn-gift-cards-italy',
                displayName: 'PSN Giftcard Italy',
                url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=36&worksIn=false',
                queryStr:
                  'platform=PSN&productType=giftcard&region=36&worksIn=false',
                idSubChild: 1258,
                navbar: true,
              },
              // {
              //   name: 'psn-gift-cards-greece',
              //   displayName: 'Greece',
              //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=49',
              //   queryStr: 'platform=PSN&productType=giftcard&region=49',
              //   idSubChild: 1259,
              // },
              {
                name: 'psn-gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
                queryStr: 'platform=PSN&productType=giftcard',
                idSubChild: 1235,
                navbar: true,
              },
              // {
              //   name: 'psn-gift-cards-taiwan',
              //   displayName: 'Taiwan',
              //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=23',
              //   queryStr: 'platform=PSN&productType=giftcard&region=23',
              //   idSubChild: 1260,
              // },
            ],
          },
          {
            name: ps_plus_by_region_msg ?? '',
            displayName: 'PS Plus by Region',
            idSub: 119,
            children: [
              {
                name: 'psn-plus-membership-united-states',
                displayName: 'PS Plus United States',
                url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=2&worksIn=false',
                queryStr:
                  'platform=PSN&productType=subscription&region=2&worksIn=false',
                idSubChild: 1261,
                navbar: true,
              },
              {
                name: 'psn-plus-membership-united-kingdom',
                displayName: 'PS Plus United Kingdom',
                url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=8&worksIn=false',
                queryStr:
                  'platform=PSN&productType=subscription&region=8&worksIn=false',
                idSubChild: 1262,
                navbar: true,
              },
              {
                name: 'psn-plus-membership-brazil',
                displayName: 'PS Plus Brazil',
                url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=15&worksIn=false',
                queryStr:
                  'platform=PSN&productType=subscription&region=15&worksIn=false',
                idSubChild: 1263,
                navbar: true,
              },
              {
                name: 'psn-plus-membership-germany',
                displayName: 'PS Plus Germany',
                url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=13&worksIn=false',
                queryStr:
                  'platform=PSN&productType=subscription&region=13&worksIn=false',
                idSubChild: 1264,
                navbar: true,
              },
              {
                name: 'psn-plus-membership',
                displayName: view_all_msg ?? '',
                url: '/store?platform[]=PSN&productType[]=subscription&page=1',
                queryStr: 'platform=PSN&productType=subscription',
                idSubChild: 1264,
                navbar: true,
              },
              // {
              //   name: 'playStation-gift-cards-europe',
              //   displayName: 'PlayStation Gift Cards Europe',
              //   url: '/store?platform[]=PSN&productType[]=giftcard&region[]=1&page=1',
              //   queryStr: 'platform=PSN&productType=giftcard&region=1',
              // },
            ],
          },
          // {
          //   name: 'Price Range',
          //   displayName: 'Price Range',
          //   idSub: 108,
          //   children: [
          //     {
          //       name: 'psn-gift-cards',
          //       displayName: 'PSN Gift Cards',
          //       url: '/store?platform[]=PSN&productType[]=giftcard&page=1',
          //       queryStr: 'platform=PSN&productType=giftcard',
          //       idSubChild: 1050,
          //     },
          //     {
          //       name: 'psn-plus-membership',
          //       displayName: 'PSN Plus Membership',
          //       url: '/store?platform[]=PSN&productType[]=subscription&page=1',
          //       queryStr: 'platform=PSN&productType=subscription',
          //       idSubChild: 1051,
          //     },
          //   ],
          // },
          // {
          //   name: 'Memberships by country',
          //   displayName: 'Memberships by country',
          //   children: [
          //     {
          //       name: 'PS Plus Membership US',
          //       displayName: 'PS Plus Membership US',
          //       url: '',
          //     },
          //   ],
          // },
        ],
        navbar: true,
      },
      {
        name: 'xbox',
        displayName: 'Xbox',
        url: '/store?page=1&platform[]=Xbox+Live',
        queryStr: 'platform=Xbox+Live',
        id: 4,
        subDivision: [
          {
            name: platform_products_msg?.replace('[_PLATFORM_]', 'XBOX') ?? '',
            displayName: 'XBOX Products',
            idSub: 110,
            children: [
              {
                name: 'xbox-games',
                displayName: xbox_games_msg ?? '',
                url: '/store?platform=Xbox+Live&page=1&productType=game',
                queryStr: 'platform=Xbox+Live&page=1&productType=game',
                idSubChild: 1085,
                navbar: true,
              },
              {
                name: 'xbox-dlc',
                displayName: 'Xbox DLCs',
                url: '/store?platform=Xbox+Live&page=1&productType=dlc',
                queryStr: 'platform=Xbox+Live&page=1&productType=dlc',
                idSubChild: 1085,
                navbar: true,
              },
              {
                name: 'xbox-gamepoints',
                displayName: 'Xbox Gamepoints',
                url: '/store?platform=Xbox+Live&page=1&productType=game+point',
                queryStr: 'platform=Xbox+Live&page=1&productType=game+point',
                idSubChild: 1085,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards',
                displayName: 'Xbox Giftcards',
                url: '/store?platform=Xbox+Live&productType=giftcard',
                queryStr: 'platform=Xbox+Live&productType=giftcard',
                idSubChild: 1090,
                navbar: true,
              },
              {
                name: 'xbox-subscription',
                displayName: 'Xbox Subscriptions',
                url: '/store?platform=Xbox+Live&page=1&productType=subscription',
                queryStr: 'platform=Xbox+Live&page=1&productType=subscription',
                idSubChild: 1085,
                navbar: true,
              },
              {
                name: 'xbox',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Xbox+Live',
                queryStr: 'platform=Xbox+Live',
                idSubChild: 1111,
                navbar: true,
              },
              // {
              //   name: '[pdp]forza-motorsport-eg-pc-xbox-series-xs-xbox-live-digital-code-p9888288',
              //   displayName: 'Forza Motorspot',
              //   url: '/store?q=forza+motorspot&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=forza+motorspot&platform=Xbox+Live&productType=game',
              //   idSubChild: 1085,
              // },
              // {
              //   name: '[pdp]mortal-kombat-1-xbox-series-x-s-xbox-live-digital-code-p9887002',
              //   displayName: 'Mortal Kombat 1',
              //   url: '/store?q=mortal+kombat+1&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=mortal+kombat+1&platform=Xbox+Live&productType=game',
              //   idSubChild: 1086,
              // },
              // {
              //   name: '[pdp]mortal-kombat-xl-ar-xbox-one-xbox-series-xs-xbox-live-digital-code-p9884822',
              //   displayName: 'Mortal Kombat XL',
              //   url: '/store?q=mortal+kombat+xl&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr:
              //     'q=mortal+kombat+xl&platform=Xbox+Live&productType=game',
              //   idSubChild: 1087,
              // },
              // {
              //   name: '[pdp]ea-sports-fc-24-xbox-one-xbox-series-xs-xbox-live-digital-code-p9886842',
              //   displayName: 'EA SPORTS FC 24',
              //   url: '/store?q=ea+sports+fc+24&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=ea+sports+fc+24&platform=Xbox+Live&productType=game',
              //   idSubChild: 1088,
              // },
              // {
              //   name: '[pdp]cuphead-ar-xbox-one-cd-key-p587404',
              //   displayName: 'Cuphead',
              //   url: '/store?q=cuphead&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=cuphead&platform=Xbox+Live&productType=game',
              //   idSubChild: 1089,
              // },
              // {
              //   name: '[pdp]assassins-creed-mirage-xbox-ar-xbox-live-digital-code-p9886933',
              //   displayName: "Assassin's Creed",
              //   url: '/store?q=assassins+creed&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=assassins+creed&platform=Xbox+Live&productType=game',
              //   idSubChild: 1090,
              // },
              // {
              //   name: '[pdp]palworld-eu-pc-xbox-one-xbox-series-xs-xbox-live-digital-code',
              //   displayName: 'Palworld',
              //   url: '/store?q=palworld&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=palworld&platform=Xbox+Live&productType=game',
              //   idSubChild: 1091,
              // },
              // {
              //   name: '[pdp]elden-ring-xbox-one-xbox-series-xs-xbox-live-digital-code-p9884430',
              //   displayName: 'Elden Ring',
              //   url: '/store?q=elden+ring&platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=elden+ring&platform=Xbox+Live&productType=game',
              //   idSubChild: 1092,
              // },
              // {
              //   name: 'xbox-games',
              //   displayName: 'View All',
              //   url: 'store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'platform=Xbox+Live&productType=game',
              //   idSubChild: 1093,
              // },
              // {
              //   name: '[pdp]red-dead-redemption-2-xbox-one-cd-key-p671161',
              //   displayName: 'Red Dead Redemption 2',
              //   url: '/q=red+dead+redemption+2&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr:
              //     'q=red+dead+redemption+2&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: '[pdp]starfield-premium-edition-pc-xbox-series-xs-xbox-live-digital-code-p9886574',
              //   displayName: 'Starfield',
              //   url: '/q=starfield&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=starfield&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: '[pdp]diablo-iv-eu-xbox-one-xbox-series-xs-xbox-live-digital-code',
              //   displayName: 'Diablo IV',
              //   url: '/q=diablo+iv&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=diablo+iv&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'far-cry-6',
              //   displayName: 'Far Cry 6',
              //   url: '/q=far+cry+6&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=far+cry+6&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'call-of-duty',
              //   displayName: 'Call Of Duty',
              //   url: '/q=call+of+duty&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=call+of+duty&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'spyro-reignited-triology',
              //   displayName: 'Spyro: Reignited Triology',
              //   url: '/q=spyro+reignited+triology&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr:
              //     'q=spyro+reignited+triology&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'doom',
              //   displayName: 'Doom',
              //   url: '/q=doom&store?platform[]=Xbox+Live&page=1&productType[]=game',
              //   queryStr: 'q=doom&platform=Xbox+Live&productType=game',
              //   idSubChild: 1044,
              // },
            ],
          },
          // {
          //   name: 'Xbox Gift Cards',
          //   displayName: 'XBOX Gift Cards',
          //   idSub: 111,
          //   children: [
          //     // {
          //     //   name: 'xbox-games',
          //     //   displayName: 'Xbox Games',
          //     //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=game',
          //     //   queryStr: 'platform=Xbox+Live&productType=game',
          //     //   idSubChild: 1044,
          //     // },
          //     {
          //       name: '[pdp]xbox-100-try-gift-card-tr-digital-card-p9888293',
          //       displayName: 'Xbox ₺100 TRY Gift Card (TR)',
          //       url: '/store?q=xbox+₺100+try+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21',
          //       queryStr:
          //         'q=xbox+₺100+try+giftcard&platform=Xbox+Live&productType=giftcard&region=21',
          //       idSubChild: 1094,
          //     },
          //     {
          //       name: '[pdp]xbox-5-usd-gift-card-us-digital-code',
          //       displayName: 'Xbox $5 USD Gift Card (US)',
          //       url: '/store?q=xbox+$5+usd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
          //       queryStr:
          //         'q=xbox+$5+usd+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
          //       idSubChild: 1095,
          //     },
          //     {
          //       name: '[pdp]xbox-10-usd-gift-card-us-digital-code',
          //       displayName: 'Xbox $10 USD Gift Card (US)',
          //       url: '/store?q=xbox+$10+usd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
          //       queryStr:
          //         'q=xbox+$10+usd+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
          //       idSubChild: 1096,
          //     },
          //     {
          //       name: '[pdp]xbox-r25-brl-gift-card-br-digital-code-p9883991',
          //       displayName: 'Xbox R$25 BRL Gift Card (BR)',
          //       url: '/store?q=xbox+R$25+brl+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
          //       queryStr:
          //         'q=xbox+R$25+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
          //       idSubChild: 1097,
          //     },
          //     {
          //       name: '[pdp]xbox-300-try-gift-card-tr-digital-card-p9891564',
          //       displayName: 'Xbox ₺300 TRY Gift Card (TR)',
          //       url: '/store?q=xbox+₺300+try+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21',
          //       queryStr:
          //         'q=xbox+₺300+try+giftcard&platform=Xbox+Live&productType=giftcard&region=21',
          //       idSubChild: 1098,
          //     },
          //     {
          //       name: '[pdp]xbox-600-zar-gift-card-South-Africa-digital-code-p9886343',
          //       displayName: 'Xbox 600 ZAR Gift Card (ZA) ',
          //       url: '/store?q=xbox+600+zar+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=57',
          //       queryStr:
          //         'q=xbox+600+zar+giftcard&platform=Xbox+Live&productType=giftcard&region=57',
          //       idSubChild: 1099,
          //     },
          //     {
          //       name: '[pdp]xbox-r5-brl-gift-card-br-digital-code-p9883962',
          //       displayName: 'Xbox R$5 BRL Gift Card (BR) ',
          //       url: '/store?q=xbox+$5+brl+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
          //       queryStr:
          //         'q=xbox+$5+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
          //       idSubChild: 1100,
          //     },
          //     {
          //       name: '[pdp]xbox-15-nzd-gift-card-new-zealand-digital-card-p9886320',
          //       displayName: 'Xbox $15 NZD Gift Card (NZ)',
          //       url: '/store?q=xbox+$15+nzd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41',
          //       queryStr:
          //         'q=xbox+$15+nzd+giftcard&platform=Xbox+Live&productType=giftcard&region=41',
          //       idSubChild: 1101,
          //     },
          //     {
          //       name: 'xbox-giftcards',
          //       displayName: 'View All',
          //       url: '/store?platform=Xbox+Live&productType=giftcard',
          //       queryStr: 'platform=Xbox+Live&productType=giftcard',
          //       idSubChild: 1102,
          //     },
          //     // {
          //     //   name: '[pdp]xbox-50-eur-gift-card-de-digital-code',
          //     //   displayName: 'Xbox €50 EUR Gift Card (DE)  ',
          //     //   url: '/q=xbox+€50+eur+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=13',
          //     //   queryStr:
          //     //     'q=xbox+€50+eur+giftcard&platform=Xbox+Live&productType=giftcard&region=13',
          //     //   idSubChild: 1044,
          //     // },
          //     // {
          //     //   name: '[pdp]xbox-75-eur-gift-card-eu-digital-code',
          //     //   displayName: 'Xbox €75 EUR Gift Card (EU)  ',
          //     //   url: '/q=xbox+€75+eur+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
          //     //   queryStr:
          //     //     'q=xbox+€75+eur+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
          //     //   idSubChild: 1044,
          //     // },
          //     // {
          //     //   name: '[pdp]xbox-r15-brl-gift-card-br-digital-code-p9886371',
          //     //   displayName: 'Xbox R$15 BRL Gift Card (BR)',
          //     //   url: '/q=xbox+R$15+brl+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
          //     //   queryStr:
          //     //     'q=xbox+R$15+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
          //     //   idSubChild: 1044,
          //     // },
          //     // {
          //     //   name: '[pdp]xbox-100-nzd-gift-card-new-zealand-digital-card-p9886316',
          //     //   displayName: 'Xbox $100 NZD Gift Card (NZ)',
          //     //   url: '/q=xbox+$100+nzd+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41',
          //     //   queryStr:
          //     //     'q=xbox+$100+nzd+giftcard&platform=Xbox+Live&productType=giftcard&region=41',
          //     //   idSubChild: 1044,
          //     // },
          //   ],
          // },
          // {
          //   name: 'Xbox Game Pass',
          //   displayName: 'XBOX Game Pass',
          //   idSub: 112,
          //   children: [
          //     {
          //       name: '[pdp]xbox-live-gold-1-months-xbox-live-digital-code-p9884093',
          //       displayName: 'Xbox Game Pass Core 1 Month',
          //       url: '/store?q=xbox+game+pass+core+1+month&platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+core+1+month&productType=subscription&platform=Xbox+Live',
          //       idSubChild: 1103,
          //     },
          //     {
          //       name: '[pdp]xbox-live-gold-3-months-xbox-live-digital-code-p9884072',
          //       displayName: 'Xbox Game Pass Core 3 Months',
          //       url: '/q=xbox+game+pass+core+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+core+3+months&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1104,
          //     },
          //     {
          //       name: '[pdp]xbox-game-pass-core-6-months-eu-xbox-live-digital-code-p9885491',
          //       displayName: 'Xbox Game Pass Core 6 Months',
          //       url: '/q=xbox+game+pass+core+6+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+core+6+months&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1105,
          //     },
          //     {
          //       name: '[pdp]xbox-live-gold-12-months-us-xbox-live-digital-code-p9884350',
          //       displayName: 'Xbox Game Pass Core 12 Months',
          //       url: '/q=xbox+game+pass+core+12+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+core+12+months&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1106,
          //     },
          //     {
          //       name: '[pdp]xbox-game-pass-ultimate-1-month-xbox-live-digital-code-p655874',
          //       displayName: 'Xbox Game Pass Ultimate - 1 Month',
          //       url: '/q=xbox+game+pass+ultimate+1+month&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+ultimate+1+month&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1107,
          //     },
          //     {
          //       name: '[pdp]xbox-game-pass-ultimate-3-months-xbox-live-digital-code-p125206',
          //       displayName: 'Xbox Game Pass Ultimate - 3 Months',
          //       url: '/q=xbox+game+pass+ultimate+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+ultimate+3+months&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1108,
          //     },
          //     {
          //       name: '[pdp]xbox-game-pass-ultimate-12-month-us-xbox-live-digital-code-p9893933',
          //       displayName: 'Xbox Game Pass Ultimate - 12 Months',
          //       url: '/q=xbox+game+pass+ultimate+12+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr:
          //         'q=xbox+game+pass+ultimate+12+months&platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1109,
          //     },

          //     // {
          //     //   name: '[pdp]xbox-game-pass-for-pc-trial-3-months-digital-code-p9884875',
          //     //   displayName: 'Xbox Game Pass For PC Trial - 3 Months',
          //     //   url: '/q=xbox+game+pass+for+pc+trial+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //     //   queryStr:
          //     //     'q=xbox+game+pass+for+pc+trial+3+months&platform=Xbox+Live&productType=subscription',
          //     //   idSubChild: 1110,
          //     // },
          //     {
          //       name: 'xbox-subscription',
          //       displayName: 'View All',
          //       url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //       queryStr: 'platform=Xbox+Live&productType=subscription',
          //       idSubChild: 1111,
          //     },
          //     // {
          //     //   name: 'ea-play-6-month-subscription',
          //     //   displayName: 'EA Play 6 Month Subscription - Xbox Live',
          //     //   url: '/q=ea+play+6+month+subscription+xbox+live&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //     //   queryStr:
          //     //     'q=ea+play+6+month+subscription+xbox+live&platform=Xbox+Live&productType=subscription',
          //     //   idSubChild: 1044,
          //     // },
          //     // {
          //     //   name: 'ea-play-12-months-subscription',
          //     //   displayName: 'EA Play 12 Months Subscription - Xbox Live',
          //     //   url: '/q=ea+play+12+months+subscription+xbox+live&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
          //     //   queryStr:
          //     //     'q=ea+play+12+months+subscription+xbox+live&platform=Xbox+Live&productType=subscription',
          //     //   idSubChild: 1044,
          //     // },
          //   ],
          // },
          {
            name: gift_cards_by_region_msg ?? '',
            displayName: 'Gift Cards By Region',
            idSub: 113,
            children: [
              // {
              //   name: 'xbox-giftcard-global',
              //   displayName: 'Xbox Live Global',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=3',
              //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=3',
              //   idSubChild: 1112,
              // },
              {
                name: 'xbox-gift-cards-united-states',
                displayName: 'Xbox Live United States',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=2&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=2&worksIn=false',
                idSubChild: 1113,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-turkey',
                displayName: 'Xbox Live Turkey',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=21&worksIn=false',
                idSubChild: 1114,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-brazil',
                displayName: 'Xbox Live Brazil',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=15&worksIn=false',
                idSubChild: 1115,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-south-africa',
                displayName: 'Xbox Live South Africa',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=57&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=57&worksIn=false',
                idSubChild: 1116,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-europe',
                displayName: 'Xbox Live Europe',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=1&worksIn=false',
                idSubChild: 1117,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-new-zealand',
                displayName: 'Xbox Live New Zealand',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=41&worksIn=false',
                idSubChild: 1118,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards-germany',
                displayName: 'Xbox Live Germany',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=13&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=giftcard&region=13&worksIn=false',
                idSubChild: 1119,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Xbox+Live&productType=giftcard&page=1',
                queryStr: 'platform=Xbox+Live&productType=giftcard',
                idSubChild: 1111,
                navbar: true,
              },
              // {
              //   name: 'xbox-gift-cards-united-kingdom',
              //   displayName: 'Xbox Live United Kingdom',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=8',
              //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=8',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-gift-cards-poland',
              //   displayName: 'Xbox Live Poland',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=19',
              //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=19',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-gift-cards-france',
              //   displayName: 'Xbox Live France',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=34',
              //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=34',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-gift-cards-australia',
              //   displayName: 'Xbox Live Australia',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=14',
              //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=14',
              //   idSubChild: 1044,
              // },
            ],
          },
          {
            name:
              platform_subscription_by_region_msg?.replace(
                '[_PLATFORM_]',
                'Xbox'
              ) ?? '',
            displayName: 'Xbox Subscriptions By Region',
            idSub: 114,
            children: [
              {
                name: 'xbox-subscriptions-global',
                displayName: 'Xbox Subscriptions Global',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=3&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=3&worksIn=false',
                idSubChild: 1120,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-united-states',
                displayName: 'Xbox Subscriptions United States',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=2&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=2&worksIn=false',
                idSubChild: 1121,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-turkey',
                displayName: 'Xbox Subscriptions Turkey',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=21&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=21&worksIn=false',
                idSubChild: 1122,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-brazil',
                displayName: 'Xbox Subscriptions Brazil',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=15&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=15&worksIn=false',
                idSubChild: 1123,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-south-africa',
                displayName: 'Xbox Subscriptions South Africa',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=57&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=57&worksIn=false',
                idSubChild: 1124,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-europe',
                displayName: 'Xbox Subscriptions Europe',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=1&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=1&worksIn=false',
                idSubChild: 1125,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-new-zealand',
                displayName: 'Xbox Subscriptions New Zealand',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=41&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=41&worksIn=false',
                idSubChild: 1226,
                navbar: true,
              },
              {
                name: 'xbox-subscriptions-germany',
                displayName: 'Xbox Subscriptions Germany',
                url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=13&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType=subscription&region=13&worksIn=false',
                idSubChild: 1227,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Xbox+Live&productType=giftcard',
                queryStr: 'platform=Xbox+Live&productType=giftcard',
                idSubChild: 1111,
                navbar: true,
              },
              // {
              //   name: 'xbox-subscription-united-kingdom',
              //   displayName: 'Xbox Plus United Kingdom',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=8',
              //   queryStr: 'platform=Xbox+Live&productType=subscription&region=8',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-subscription-poland',
              //   displayName: 'Xbox Plus Poland',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=19',
              //   queryStr: 'platform=Xbox+Live&productType=subscription&region=19',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-subscription-france',
              //   displayName: 'Xbox Plus France',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=34',
              //   queryStr: 'platform=Xbox+Live&productType=subscription&region=34',
              //   idSubChild: 1044,
              // },
              // {
              //   name: 'xbox-subscription-australia',
              //   displayName: 'Xbox Plus Australia',
              //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=14',
              //   queryStr: 'platform=Xbox+Live&productType=subscription&region=14',
              //   idSubChild: 1044,
              // },
            ],
          },
          // {
          //   name: 'Price Range',
          //   displayName: 'Price Range',
          //   idSub: 106,
          //   children: [
          //     {
          //       name: 'xbox-games',
          //       displayName: 'Xbox Games',
          //       url: '/store?platform[]=Xbox+Live&page=1&productType[]=game',
          //       queryStr: 'platform=Xbox+Live&productType=game',
          //       idSubChild: 1044,
          //     },
          //   ]
          // }
        ],
        navbar: true,
      },

      {
        name: 'nintendo',
        displayName: 'Nintendo',
        url: '/store?page=1&platform[]=Nintendo',
        queryStr: 'platform=Nintendo',
        id: 6,
        subDivision: [
          {
            name:
              platform_products_msg?.replace('[_PLATFORM_]', 'Nintendo') ?? '',
            displayName: 'Nintendo Products',
            idSub: 115,
            children: [
              {
                name: 'nintendo-games',
                displayName: nintendo_games_msg ?? '',
                url: '/store?platform[]=Nintendo&page=1&productType[]=game',
                queryStr: 'platform=Nintendo&productType=game',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'nintendo-dlc',
                displayName: 'Nintendo DLCs',
                url: '/store?platform[]=Nintendo&page=1&productType[]=dlc',
                queryStr: 'platform=Nintendo&productType=dlc',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards',
                displayName: 'Nintendo Giftcard',
                url: '/store?platform[]=Nintendo&page=1&productType[]=giftcard',
                queryStr: 'platform=Nintendo&productType=giftcard',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'nintendo-subsciption',
                displayName: 'Nintendo Subscription',
                url: '/store?platform[]=Nintendo&page=1&productType[]=subscription',
                queryStr: 'platform=Nintendo&productType=subscription',
                idSubChild: 1228,
                navbar: true,
              },
              {
                name: 'nintendo',
                displayName: view_all_msg ?? '',
                url: '/store?platform=Nintendo',
                queryStr: 'platform=Nintendo',
                idSubChild: 1111,
                navbar: true,
              },
              // {
              //   name: 'nintendo-gamepoint',
              //   displayName: 'Nintendo Gamepoints',
              //   url: '/store?platform[]=Nintendo&page=1&productType[]=game+point',
              //   queryStr: 'platform=Nintendo&productType=game+point',
              //   idSubChild: 1228,
              // },
            ],
          },
          // {
          //   name: 'Switch Games',
          //   displayName: 'Switch Games',
          //   idSub: 120,
          //   children: [
          //     {
          //       name: '[pdp]just-dance-2024-eu-nintendo-switch--nintendo--digital-code-p9887907',
          //       displayName: 'Just Dance 2024',
          //       url: '/just-dance-2024-eu-xbox-series-xs-xbox-live-digital-code-p9887894',
          //       queryStr:
          //         'just-dance-2024-eu-nintendo-switch--nintendo--digital-code-p9887907',
          //       idSubChild: 1265,
          //     },
          //     {
          //       name: '[pdp]assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
          //       displayName: "Assassin's Creed: Ezio Collection",
          //       url: '/assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
          //       queryStr:
          //         'assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
          //       idSubChild: 1266,
          //     },
          //     {
          //       name: '[pdp]rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
          //       displayName: 'Rabbids: Party of Legends',
          //       url: '/rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
          //       queryStr:
          //         'rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
          //       idSubChild: 1267,
          //     },
          //     {
          //       name: '[pdp]mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
          //       displayName: 'Mario Kart 8 Deluxe Edition',
          //       url: '/mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
          //       queryStr:
          //         'mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
          //       idSubChild: 1268,
          //     },
          //     {
          //       name: '[pdp]crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
          //       displayName: 'Crysis Remastered Trilogy',
          //       url: '/crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
          //       queryStr:
          //         'crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
          //       idSubChild: 1269,
          //     },
          //     {
          //       name: '[pdp]lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
          //       displayName: 'LEGO 2K Drive',
          //       url: '/lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
          //       queryStr:
          //         'lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
          //       idSubChild: 1270,
          //     },
          //     {
          //       name: '[pdp]super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
          //       displayName: 'Super Mario Odyssey',
          //       url: '/super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
          //       queryStr:
          //         'super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
          //       idSubChild: 1271,
          //     },
          //     {
          //       name: '[pdp]the-outer-worlds-eu-nintendo-switch--nintendo--digital-code-p9888203',
          //       displayName: 'The Outer Worlds',
          //       url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
          //       queryStr:
          //         'the-outer-worlds-eu-nintendo-switch--nintendo--digital-code-p9888203',
          //       idSubChild: 1272,
          //     },
          //     {
          //       name: 'nintendo-switch-games',
          //       displayName: 'View All',
          //       url: '/store?platform=Nintendo&productType[]=game',
          //       queryStr: 'platform=Nintendo&productType=game',
          //       idSubChild: 1273,
          //     },
          //     //  {
          //     //   name: '[pdp]ark-survival-evolved-eu-nintendo-switch-cd-key-p599037',
          //     //   displayName: "ARK: Survival Evolved",
          //     //   url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
          //     //   queryStr: 'ark-survival-evolved-eu-nintendo-switch-cd-key-p599037',
          //     //   idSubChild: 1056,
          //     // },
          //     //  {
          //     //   name: '[pdp]sid-meiers-civilization-vi-eu-nintendo-switch--nintendo--digital-code-p9888103',
          //     //   displayName: "Sid Meier's Civilization VI",
          //     //   url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
          //     //   queryStr: 'sid-meiers-civilization-vi-eu-nintendo-switch--nintendo--digital-code-p9888103',
          //     //   idSubChild: 1056,
          //     // },
          //   ],
          // },
          // {
          //   name: 'Nintendo Gift Cards',
          //   displayName: 'Nintendo Gift Cards',
          //   idSub: 121,
          //   children: [
          //     {
          //       name: '[pdp]nintendo-eshop-10-gift-card-us-digital-code',
          //       displayName: 'Nintendo eShop $10 (US)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr: 'nintendo-eshop-10-gift-card-us-digital-code',
          //       idSubChild: 1074,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-9000-jpy-gift-card-jp-digital-code',
          //       displayName: 'Nintendo eShop ¥9000 (JP)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr: 'nintendo-eshop-9000-jpy-gift-card-jp-digital-code',
          //       idSubChild: 1075,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-2000-jpy-gift-card-jp-digital-code',
          //       displayName: 'Nintendo eShop ¥2000 (JP)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr: 'nintendo-eshop-2000-jpy-gift-card-jp-digital-code',
          //       idSubChild: 1076,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-100-hkd-gift-card-hk-digital-code-p9883959',
          //       displayName: 'Nintendo eShop $100 (HK)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr:
          //         'nintendo-eshop-100-hkd-gift-card-hk-digital-code-p9883959',
          //       idSubChild: 1077,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-500-hkd-gift-card-hk-digital-code-p9883983',
          //       displayName: 'Nintendo eShop $500 (HK)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr:
          //         'nintendo-eshop-500-hkd-gift-card-hk-digital-code-p9883983',
          //       idSubChild: 1078,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-300-hkd-gift-card-hk-digital-code-p9883982',
          //       displayName: 'Nintendo eShop $300 (HK)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr:
          //         'nintendo-eshop-300-hkd-gift-card-hk-digital-code-p9883982',
          //       idSubChild: 1079,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-50-gift-card-us-digital-code',
          //       displayName: 'Nintendo eShop $50 (US)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr: 'nintendo-eshop-50-gift-card-us-digital-code',
          //       idSubChild: 1080,
          //     },
          //     {
          //       name: '[pdp]nintendo-eshop-100-gift-card-eu-digital-code-p9883445',
          //       displayName: 'Nintendo eShop €100 (EU)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //       queryStr: 'nintendo-eshop-100-gift-card-eu-digital-code-p9883445',
          //       idSubChild: 1081,
          //     },
          //     {
          //       name: 'nintendo-switch-giftcards',
          //       displayName: 'View All',
          //       url: '/store?platform=Nintendo&productType[]=giftcard',
          //       queryStr: 'platform=Nintendo&productType=giftcard',
          //       idSubChild: 1282,
          //     },
          //     //  {
          //     //   name: '[pdp]nintendo-eshop-75-gift-card-eu-digital-code-p9883443',
          //     //   displayName: 'Nintendo eShop €75 (EU)',
          //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //     //   queryStr:
          //     //     'nintendo-eshop-75-gift-card-eu-digital-code-p9883443',
          //     //   idSubChild: 1050,
          //     // },
          //     //  {
          //     //   name: '[pdp]nintendo-eshop-10-cad-gift-card-ca-digital-code-p9884566',
          //     //   displayName: 'Nintendo eShop $10 (CA)',
          //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
          //     //   queryStr:
          //     //     'nintendo-eshop-10-cad-gift-card-ca-digital-code-p9884566',
          //     //   idSubChild: 1050,
          //     // },
          //   ],
          // },
          // {
          //   name: 'Switch Online ',
          //   displayName: 'Switch Online Membership',
          //   idSub: 122,
          //   children: [
          //     {
          //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-gb-digital-code-p9883454',
          //       displayName: 'Switch Online 3 Months (UK)',
          //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=8',
          //       queryStr:
          //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=8',
          //       idSubChild: 1283,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-pl-digital-code',
          //       displayName: 'Switch Online 3 Months (PL)',
          //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=19',
          //       queryStr:
          //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=19',
          //       idSubChild: 1284,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-pl-digital-code',
          //       displayName: 'Switch Online 12 Months (PL)',
          //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=19',
          //       queryStr:
          //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=19',
          //       idSubChild: 1285,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-us-digital-code',
          //       displayName: 'Switch Online 12 Months (US)',
          //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1286,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-eu-digital-code-p9883452',
          //       displayName: 'Switch Online 12 Months (EU)',
          //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1287,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-uk-digital-code-p988511',
          //       displayName: 'Switch Online 12 Months (UK)',
          //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=8',
          //       queryStr:
          //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=8',
          //       idSubChild: 1288,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-us-digital-code-p360295',
          //       displayName: 'Switch Online 3 Months (US)',
          //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
          //       queryStr:
          //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=2',
          //       idSubChild: 1289,
          //     },
          //     {
          //       name: '[pdp]nintendo-switch-online-12-months-family-membership-eu-digital-code-p9887833',
          //       displayName: 'Switch Online 12 Months Family (EU)',
          //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //       queryStr:
          //         'q=Nintendo+switch+online+12+months+family&platform=PSN&productType=subscription&region=1',
          //       idSubChild: 1290,
          //     },
          //     {
          //       name: 'swicth-online-membership',
          //       displayName: 'View All',
          //       url: '/store?platform=Nintendo&productType[]=subscription&page=1',
          //       queryStr: 'platform=Nintendo&productType=subscription',
          //       idSubChild: 1291,
          //     },
          //     //   {
          //     //   name: '[pdp]nintendo-switch-online-3-months-individual-membership-eu-digital-code-p9883451',
          //     //   displayName: 'Switch Online 3 Months (EU)',
          //     //   url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //     //   queryStr:
          //     //     'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=1',
          //     //   idSubChild: 1050,
          //     // },
          //     //  {
          //     //   name: '[pdp]nintendo-switch-online-3-months-family-membership-eu-digital-code-p9896145',
          //     //   displayName: 'Nintendo Switch Online 3 Months Family (EU)',
          //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=1',
          //     //   queryStr:
          //     //     'q=Nintendo+switch+online+12+months+family&platform=PSN&productType=subscription&region=1',
          //     //   idSubChild: 1050,
          //     // },
          //   ],
          // },
          {
            name: gift_cards_by_region_msg ?? '',
            displayName: 'Gift Cards By Region',
            idSub: 123,
            children: [
              {
                name: 'nintendo-gift-cards-united-states',
                displayName: 'Nintendo eShop United States',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=2&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=2&worksIn=false',
                idSubChild: 1292,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-japan',
                displayName: 'Nintendo eShop Japan',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=17&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=17&worksIn=false',
                idSubChild: 1293,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-hong-kong',
                displayName: 'Nintendo eShop Hong kong',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=22&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=22&worksIn=false',
                idSubChild: 1294,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-europe',
                displayName: 'Nintendo eShop Europe',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=1&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=1&worksIn=false',
                idSubChild: 1295,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-united-kingdom',
                displayName: 'Nintendo eShop United Kingdom',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=8&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=8&worksIn=false',
                idSubChild: 1296,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-mexico',
                displayName: 'Nintendo eShop Mexico',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=32&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=32&worksIn=false',
                idSubChild: 1297,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-canada',
                displayName: 'Nintendo eShop Canada',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=28&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=28&worksIn=false',
                idSubChild: 1298,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards-brazil',
                displayName: 'Nintendo eShop Brazil',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=15&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=giftcard&region=15&worksIn=false',
                idSubChild: 1299,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1',
                queryStr: 'platform=Nintendo&productType=giftcard',
                idSubChild: 1299,
                navbar: true,
              },
            ],
          },
          {
            name:
              platform_subscription_by_region_msg?.replace(
                '[_PLATFORM_]',
                'Nintendo'
              ) ?? '',
            displayName: 'Nintendo Subscriptions By Region',
            idSub: 124,
            children: [
              {
                name: 'nintendo-subscriptions-poland',
                displayName: 'Nintendo Subscriptions Poland',
                url: '/store?platform=NintendoproductType[]=subscription&page=1&region=19&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=subscription&region=19&worksIn=false',
                idSubChild: 1300,
                navbar: true,
              },
              {
                name: 'nintendo-subscriptions-europe',
                displayName: 'Nintendo Subscriptions Europe',
                url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=1&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=subscription&region=1&worksIn=false',
                idSubChild: 1301,
                navbar: true,
              },
              {
                name: 'nintendo-subscriptions-united-states',
                displayName: 'Nintendo Subscriptions United States',
                url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=2&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=subscription&region=2&worksIn=false',
                idSubChild: 1302,
                navbar: true,
              },
              {
                name: 'nintendo-subscriptions-united-kingdom',
                displayName: 'Nintendo Subscriptions United Kingdom',
                url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=8&worksIn=false',
                queryStr:
                  'platform=Nintendo&productType=subscription&region=8&worksIn=false',
                idSubChild: 1303,
                navbar: true,
              },
              {
                name: 'nintendo-subscriptions',
                displayName: view_all_msg ?? '',
                url: '/store?platform[]=Nintendo&productType[]=subscription&page=1',
                queryStr: 'platform=Nintendo&productType=subscription',
                idSubChild: 1303,
                navbar: true,
              },
            ],
          },
          // {
          //   name: 'Price Range',
          //   displayName: 'Price Range',
          //   idSub: 111,
          //   children: [
          //     {
          //       name: 'Nintendo by price (Low to High)',
          //       displayName: 'Nintendo by price (Low to High)',
          //       url: '/store?platform[]=Nintendo&page=1&sort=l2h',
          //       queryStr: 'platform=Nintendo&page=1&sort=l2h',
          //       idSubChild: 1057,
          //     },
          //     {
          //       name: 'Nintendo by price (High to Low)',
          //       displayName: 'Nintendo by price (High to Low)',
          //       url: '/store?platform[]=Nintendo&page=1&sort=h2l',
          //       queryStr: 'platform=Nintendo&page=1&sort=h2l',
          //       idSubChild: 1058,
          //     },
          //   ],
          // },
          // {
          //   name: 'Gift cards by region',
          //   displayName: 'Gift cards by region',
          //   children: [
          //     {
          //       name: 'Nintendo eShop Cards US',
          //       displayName: 'Nintendo eShop Cards US',
          //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region[]=2',
          //     },
          //   ],
          // },
          // {
          //   name: 'Memberships by Region',
          //   displayName: 'Memberships by Region',
          //   children: [
          //     {
          //       name: 'Nintendo Online Memberships US',
          //       displayName: 'Nintendo Online Memberships US',
          //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region[]=2',
          //     },
          //   ],
          // },
        ],
        navbar: true,
      },

      // {
      //   name: 'PC',
      //   displayName: 'PC',
      //   url: '/store?worksOn[]=windows&worksOn[]=mac&worksOn[]=linux&page=1',
      //   queryStr: 'worksOn[]=windows&worksOn[]=mac&worksOn[]=linux&page=1',
      //   id: 7,
      //   subDivision: [
      //     {
      //       name: 'PC Games',
      //       displayName: 'PC Games',
      //       idSub: 125,
      //       children: [
      //         {
      //           name: '[pdp]grand-theft-auto-v-rockstar-digital-download-cd-key-p510811',
      //           displayName: 'Grand Theft Auto V',
      //           url: '/grand-theft-auto-v-rockstar-digital-download-cd-key-p510811',
      //           queryStr: '',
      //           idSubChild: 1304,
      //         },
      //         {
      //           name: '[pdp]palworld-pc-steam-digital-code-p9893165',
      //           displayName: 'Palworld',
      //           url: '/palworld-pc-steam-digital-code-p9893165',
      //           queryStr: '',
      //           idSubChild: 1305,
      //         },
      //         {
      //           name: '[pdp]cities-skylines-ii-pc-steam-digital-code-p9886770',
      //           displayName: 'Cities: Skylines II',
      //           url: '/cities-skylines-ii-pc-steam-digital-code-p9886770',
      //           queryStr: '',
      //           idSubChild: 1306,
      //         },
      //         {
      //           name: '[pdp]hell-let-loose-p9879310',
      //           displayName: 'Hell Let Loose',
      //           url: '/hell-let-loose-p9879310',
      //           queryStr: '',
      //           idSubChild: 1307,
      //         },
      //         {
      //           name: '[pdp]ea-sports-fc-24-pc-ea-play-digital-code-p9886721',
      //           displayName: 'EA SPORTS FC 24',
      //           url: '/ea-sports-fc-24-pc-ea-play-digital-code-p9886721',
      //           queryStr: '',
      //           idSubChild: 1308,
      //         },
      //         {
      //           name: '[pdp]helldivers-2-row-pc-steam-digital-code-p9895570',
      //           displayName: 'Helldivers 2',
      //           url: '/helldivers-2-row-pc-steam-digital-code-p9895570',
      //           queryStr: '',
      //           idSubChild: 1309,
      //         },
      //         {
      //           name: '[pdp]mount-blade-ii-bannerlord-p9879952',
      //           displayName: 'Mount & Blade II: Bannerlord',
      //           url: '/mount-blade-ii-bannerlord-p9879952',
      //           queryStr: '',
      //           idSubChild: 1310,
      //         },
      //         {
      //           name: '[pdp]ready-or-not-eu-pc-steam-digital-code-p9884380',
      //           displayName: 'Ready or Not',
      //           url: '/ready-or-not-eu-pc-steam-digital-code-p9884380',
      //           queryStr: '',
      //           idSubChild: 1311,
      //         },
      //         {
      //           name: '[pdp]minecraft-java-bedrock-edition-pc-microsoft-store-digital-code-p9879164',
      //           displayName: 'Minecraft Java and Bedrock Edition',
      //           url: '/minecraft-java-bedrock-edition-pc-microsoft-store-digital-code-p9879164',
      //           queryStr: '',
      //           idSubChild: 1312,
      //         },
      //         // {
      //         //   name: '[pdp]red-dead-redemption-2-rockstar-digital-download-cd-key-p925113',
      //         //   displayName: 'Red Dead Redemption 2',
      //         //   url: '/red-dead-redemption-2-rockstar-digital-download-cd-key-p925113',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Elden Ring ',
      //         //   displayName: 'Elden Ring ',
      //         //   url: '/elden-ring-pc-steam-digital-code-p9884385',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Monster Hunter World',
      //         //   displayName: 'Monster Hunter World',
      //         //   url: '/monster-hunter-world-pc-steam-digital-code',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Hogwarts Legacy',
      //         //   displayName: 'Hogwarts Legacy',
      //         //   url: '/hogwarts-legacy-pc-steam-digital-code-p9883455',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Horizon Zero Dawn Complete Edition',
      //         //   displayName: 'Horizon Zero Dawn Complete Edition',
      //         //   url: '/horizon-zero-dawn-complete-edition-steam-cd-key-p676042',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'PC softwares',
      //       displayName: 'PC softwares',
      //       idSub: 126,
      //       children: [
      //         {
      //           name: '[pdp]exitlag-1-month-software-license-digital-code-p9888286',
      //           displayName: 'ExitLag',
      //           url: '/exitlag-1-month-software-license-digital-code-p9888286',
      //           queryStr: '',
      //           idSubChild: 1313,
      //         },
      //         {
      //           name: '[pdp]3dmark-steam-cd-key-p784330',
      //           displayName: '3DMark',
      //           url: '/3dmark-steam-cd-key-p784330',
      //           queryStr: '',
      //           idSubChild: 1314,
      //         },
      //         {
      //           name: '[pdp]ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           displayName: 'CCleaner Professional',
      //           url: '/ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           queryStr: '',
      //           idSubChild: 1315,
      //         },
      //         {
      //           name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           displayName: 'Dolby Atmos for Headphones',
      //           url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           queryStr: '',
      //           idSubChild: 1316,
      //         },
      //         // {
      //         //   name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //         //   displayName: 'Dolby Atmos for Headphones',
      //         //   url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //         //   queryStr: '',
      //         //   idSubChild: 1317,
      //         // },
      //         {
      //           name: '[pdp]aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //           displayName: 'AOMEI Backupper Professional Edition 2023',
      //           url: '/aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //           queryStr: '',
      //           idSubChild: 1318,
      //         },
      //         {
      //           name: '[pdp]avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           displayName: 'AVG Ultimate',
      //           url: '/avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           queryStr: '',
      //           idSubChild: 1319,
      //         },
      //         {
      //           name: '[pdp]mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           displayName: 'McAfee AntiVirus',
      //           url: '/mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           queryStr: '',
      //           idSubChild: 1320,
      //         },
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           displayName: 'AUTODESK AUTOCAD (2023)',
      //           url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           queryStr: '',
      //           idSubChild: 1318,
      //         },
      //         // {
      //         //   name: 'PC-softwares',
      //         //   displayName: 'View All',
      //         //   url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //         //   queryStr:
      //         //     'productType=software&worksOn[]=windows&worksOn[]=mac&worksOn[]=linux',
      //         //   idSubChild: 1318,
      //         // },

      //         {
      //           name: 'McAfee Total Protection',
      //           displayName: 'McAfee Total Protection',
      //           url: '/mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           queryStr: '',
      //           idSubChild: 1056,
      //         },
      //         // {
      //         //   name: 'AUTODESK AUTOCAD (2024)',
      //         //   displayName: 'AUTODESK AUTOCAD (2024)',
      //         //   url: '/autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Eset Smart Security',
      //         //   displayName: 'Eset Smart Security',
      //         //   url: '/eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Kaspersky Total Security',
      //         //   displayName: 'Kaspersky Total Security',
      //         //   url: '/kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'AOMEI Backupper Professional Edition 2023',
      //         //   displayName: 'AOMEI Backupper Professional Edition 2023',
      //         //   url: '/aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Avast Ultimate',
      //         //   displayName: 'Avast Ultimate',
      //         //   url: '/avast-ultimate-pc-1-device-2-years-digital-code-p9886539',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'PC Gift Cards',
      //       displayName: 'PC Gift Cards',
      //       idSub: 127,
      //       children: [
      //         {
      //           name: '[pdp]steam-gift-card-500-inr-activation-code-p622515',
      //           displayName: 'Steam Wallet ₹500 (IN)',
      //           url: '/store?q=Steam%20Wallet%20₹500%20(IN)',
      //           queryStr: 'store?q=Steam%20Wallet%20₹500%20(IN)',
      //           idSubChild: 1321,
      //         },
      //         {
      //           name: '[pdp]xbox-game-pass-for-pc-trial-3-months-digital-code-p9884875',
      //           displayName: 'Xbox Game Pass for PC Trial',
      //           url: '/store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           queryStr: 'store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           idSubChild: 1322,
      //         },
      //         {
      //           name: '[pdp]ea-origin-15-eur-eu-pc-ea-play-digital-code',
      //           displayName: 'EA Origin 15 EUR',
      //           url: '/ea-origin-15-eur-eu-pc-ea-play-digital-code',
      //           queryStr: '',
      //           idSubChild: 1323,
      //         },
      //         {
      //           name: '[pdp]diablo-iv-gift-card-bundle-70-us-battlenet-digital-code',
      //           displayName: 'Diablo IV - Gift Card Bundle $70 (US)',
      //           url: '/store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           queryStr: 'store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           idSubChild: 1324,
      //         },
      //         {
      //           name: '[pdp]blizzard-10-gift-card-us-digital-code-p9883433',
      //           displayName: 'Blizzard $10 Gift Card (US)',
      //           url: '/blizzard-10-gift-card-us-digital-code-p9883433',
      //           queryStr: 'blizzard-10-gift-card-us-digital-code-p9883433',
      //           idSubChild: 1325,
      //         },
      //         {
      //           name: '[pdp]ea-play-15-usd-gift-card-us-digital-code-p9886250',
      //           displayName: 'EA Play $15 USD Gift Card (US))',
      //           url: '/store?q=EA%20Play%20$15%20USD%20Gift%20Card%20(US)',
      //           queryStr: 'store?q=EA%20Play%20$15%20USD%20Gift%20Card%20(US)',
      //           idSubChild: 1326,
      //         },
      //         {
      //           name: '[pdp]riot-access-usd-10-code-us-p9878679',
      //           displayName: 'Riot Access $10',
      //           url: '/riot-access-usd-10-code-us-p9878679',
      //           queryStr: '',
      //           idSubChild: 1327,
      //         },
      //         {
      //           name: '[pdp]valorant-5-eur-gift-card-eu-digital-code',
      //           displayName: 'Valorant €5 EUR Gift Card (EU)',
      //           url: '/valorant-5-eur-gift-card-eu-digital-code',
      //           queryStr: '',
      //           idSubChild: 1328,
      //         },
      //         {
      //           name: '[pdp]roblox-200-robux-digital-code-p9884392',
      //           displayName: 'Roblox - 200 Robux',
      //           url: '/roblox-200-robux-digital-code-p9884392',
      //           queryStr: '',
      //           idSubChild: 1329,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Items By Company',
      //       displayName: 'Items By Company',
      //       idSub: 128,
      //       children: [
      //         {
      //           name: 'steam',
      //           displayName: 'Steam',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=Steam',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=Steam',
      //           idSubChild: 1330,
      //         },
      //         {
      //           name: 'battle-net',
      //           displayName: 'Battle.net',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=Battle.net',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=Battle.net',
      //           idSubChild: 1331,
      //         },
      //         {
      //           name: 'avg',
      //           displayName: 'AVG',
      //           url: '/store?q=avg&worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1',
      //           queryStr: 'q=avg&worksOn=linux&worksOn=mac&worksOn=windows',
      //           idSubChild: 1401,
      //         },
      //         {
      //           name: 'EA-play',
      //           displayName: 'EA Play',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=EA+Play',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=EA+Play',
      //           idSubChild: 1332,
      //         },
      //         {
      //           name: 'aomei',
      //           displayName: 'AOMEI',
      //           url: '/store?q=aomei&worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1',
      //           queryStr: 'q=aomei&worksOn=linux&worksOn=mac&worksOn=windows',
      //           idSubChild: 1402,
      //         },
      //         {
      //           name: 'ubisoft-connect',
      //           displayName: 'Ubisoft Connect',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Ubisoft+Connect',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Ubisoft+Connect',
      //           idSubChild: 1333,
      //         },
      //         {
      //           name: 'epic-games',
      //           displayName: 'Epic Games',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Epic+Games',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Epic+Games',
      //           idSubChild: 1334,
      //         },
      //         {
      //           name: 'rockstar-games',
      //           displayName: 'Rockstar Games',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Rockstar+Games',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Rockstar+Games',
      //           idSubChild: 1335,
      //         },
      //         {
      //           name: 'microsoft-store',
      //           displayName: 'Microsoft Store',
      //           url: '/store?worksOn=mac&worksOn=linux&worksOn=windows&page=1&platform[]=Microsoft+Store',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Microsoft+Store',
      //           idSubChild: 1336,
      //         },
      //         // {
      //         //   name: 'GOG Games',
      //         //   displayName: 'GOG Games',
      //         //   url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=GOG+COM',
      //         //   queryStr:
      //         //     'worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&platform[]=GOG+COM',
      //         //   idSubChild: 1337,
      //         // },
      //       ],
      //     },
      //     //  {
      //     //   name: 'Price Range',
      //     //   displayName: 'Price Range',
      //     //   idSub: 110,
      //     //   children: [
      //     //     {
      //     //       name: 'nintendo-switch-games',
      //     //       displayName: 'Nintendo Switch Games',
      //     //       url: '/store?platform[]=Nintendo&productType[]=game&page=1',
      //     //       queryStr: 'platform=Nintendo&productType=game',
      //     //       idSubChild: 1055,
      //     //     },
      //     //     {
      //     //       name: 'nintendo-eShop-cards',
      //     //       displayName: 'Nintendo eShop Cards',
      //     //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1',
      //     //       queryStr: 'platform=Nintendo&productType=giftcard',
      //     //       idSubChild: 1056,
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      // {
      //   name: 'softwares',
      //   displayName: 'Softwares',
      //   url: '/store?productType[]=software&page=1',
      //   queryStr: 'productType=software',
      //   id: 8,
      //   subDivision: [
      //     {
      //       name: 'System Security',
      //       displayName: 'System Security',
      //       idSub: 129,
      //       children: [
      //         {
      //           name: '[pdp]avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           displayName: 'AVG Ultimate',
      //           url: '/avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           queryStr: 'q=avg+ultimate&productType=software',
      //           idSubChild: 1337,
      //         },
      //         {
      //           name: '[pdp]mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           displayName: 'McAfee AntiVirus',
      //           url: '/mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           queryStr: 'q=amcafee+antivirus&productType=software',
      //           idSubChild: 1338,
      //         },
      //         {
      //           name: '[pdp]mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           displayName: 'McAfee Total Protection',
      //           url: '/mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           queryStr: 'q=mcafee+total+protection&productType=software',
      //           idSubChild: 1339,
      //         },
      //         {
      //           name: '[pdp]eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //           displayName: 'Eset Smart Security',
      //           url: '/eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //           queryStr: 'q=eset+smart+security&productType=software',
      //           idSubChild: 1340,
      //         },
      //         {
      //           name: '[pdp]kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //           displayName: 'Kaspersky Total Security',
      //           url: '/kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //           queryStr: 'q=kaspersky+total+security&productType=software',
      //           idSubChild: 1341,
      //         },
      //         {
      //           name: '[pdp]avast-ultimate-pc-1-device-1-year-digital-code-p9886540',
      //           displayName: 'Avast Ultimate',
      //           url: '/avast-ultimate-pc-1-device-1-year-digital-code-p9886540',
      //           queryStr: 'q=avast+ultimate&productType=software',
      //           idSubChild: 1342,
      //         },
      //         {
      //           name: '[pdp]kaspersky-internet-security-uk-2023-3-devices-1-year-digital-code-p9888413',
      //           displayName: 'Kaspersky Internet Security',
      //           url: '/kaspersky-internet-security-uk-2023-3-devices-1-year-digital-code-p9888413',
      //           queryStr: 'q=kaspersky+internet+security&productType=software',
      //           idSubChild: 1343,
      //         },
      //         {
      //           name: '[pdp]avast-premium-security-2023-1-device-1-year-digital-code-p9887975',
      //           displayName: 'Avast Premium Security',
      //           url: '/avast-premium-security-2023-1-device-1-year-digital-code-p9887975',
      //           queryStr: 'q=avast+premium+security&productType=software',
      //           idSubChild: 1344,
      //         },
      //         {
      //           name: '[pdp]eset-nod32-antivirus-2023-pc-mac-1-device-2-years-digital-code-p9889490',
      //           displayName: 'Eset NOD32 Antivirus',
      //           url: '/eset-nod32-antivirus-2023-pc-mac-1-device-2-years-digital-code-p9889490',
      //           queryStr: 'q=eset+nod32+anitivirus&productType=software',
      //           idSubChild: 1345,
      //         },
      //         // {
      //         //   name: '[pdp]bitdefender-antivirus-plus-eu-2023-1-device-2-years-digital-code-p9888354',
      //         //   displayName: 'Bitdefender Antivirus Plus',
      //         //   url: '/bitdefender-antivirus-plus-eu-2023-1-device-2-years-digital-code-p9888354',
      //         //   queryStr: 'q=bitdefender+antivirus+plus&productType=software',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'System Management',
      //       displayName: 'System Management',
      //       idSub: 130,
      //       children: [
      //         {
      //           name: '[pdp]ccleaner-professional-android-1-device-1-year-digital-code-p9891076',
      //           displayName: 'CCleaner Professional',
      //           url: '/ccleaner-professional-android-1-device-1-year-digital-code-p9891076',
      //           queryStr: 'q=cleaner+professional&productType=software',
      //           idSubChild: 1346,
      //         },
      //         {
      //           name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           displayName: 'Dolby Atmos for Headphones',
      //           url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           queryStr: 'q=dolby+atmos+for+headphones&productType=software',
      //           idSubChild: 1347,
      //         },
      //         {
      //           name: '[pdp]aomei-backupper-professional-edition-2023-1-device-lifetime-digital-code-p9886439',
      //           displayName: 'AOMEI Backupper Professional',
      //           url: '/aomei-backupper-professional-edition-2023-1-device-lifetime-digital-code-p9886439',
      //           queryStr:
      //             'q=aomei+backupper+professional+edition+2023+1+device+lifetime&productType=software',
      //           idSubChild: 1348,
      //         },
      //         // {
      //         //   name: '[pdp]aomei-backupper-professional-edition-2023-2-device-lifetime-digital-code-p9886438',
      //         //   displayName:
      //         //     'AOMEI Backupper Professional Edition 2023 - 2 Device Lifetime',
      //         //   url: '/aomei-backupper-professional-edition-2023-2-device-lifetime-digital-code-p9886438',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         {
      //           name: '[pdp]aomei-partition-assistant-professional-edition-2023-2-devices-lifetime-digital-code-p9886437',
      //           displayName: 'AOMEI Partition Assistant Professional',
      //           url: '/aomei-partition-assistant-professional-edition-2023-2-devices-lifetime-digital-code-p9886437',
      //           queryStr: '',
      //           idSubChild: 1349,
      //         },
      //         // {
      //         //   name: '[pdp]aomei-backupper-professional-edition-2023-1-device-1-year-digital-code-p9886444',
      //         //   displayName:
      //         //     'AOMEI Backupper Professional Edition 2023 1 Device 1 Year',
      //         //   url: '/aomei-backupper-professional-edition-2023-1-device-1-year-digital-code-p9886444',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         {
      //           name: '[pdp]vmware-workstation-version-17-pc-2-devices-lifetime-key-digital-code-p9890886',
      //           displayName: 'VMWARE WORKSTATION: Version 17',
      //           url: '/vmware-workstation-version-17-pc-2-devices-lifetime-key-digital-code-p9890886',
      //           queryStr: '',
      //           idSubChild: 1350,
      //         },
      //         {
      //           name: '[pdp]aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           displayName: 'AOMEI Backupper Workstation',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1351,
      //         },

      //         {
      //           name: '[pdp] aomei-onekey-recovery-family-pack-edition-4-pc-lifetime-digital-code-p9886445',
      //           displayName: 'AOMEI OneKey Recovery Family Pack',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1352,
      //         },
      //         {
      //           name: '[pdp]avast-driver-updater-1-device-3-years-digital-code-p9886546',
      //           displayName: 'Avast Driver Updater',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1353,
      //         },
      //         {
      //           name: '[pdp]avg-driver-updater-1-device-1-year-digital-code-p9886554',
      //           displayName: 'AVG Driver Updater',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1354,
      //         },
      //         // {
      //         //   name: 'AOMEI OneKey Recovery Family Pack Edition 4 PC Lifetime',
      //         //   displayName:
      //         //     'AOMEI OneKey Recovery Family Pack Edition 4 PC Lifetime',
      //         //   url: '/aomei-onekey-recovery-family-pack-edition-4-pc-lifetime-digital-code-p9886445',
      //         //   queryStr: '',
      //         //   idSubChild: 1055,
      //         // },
      //         // {
      //         //   name: 'Avast Driver Updater',
      //         //   displayName: 'Avast Driver Updater',
      //         //   url: '/avast-driver-updater-1-device-3-years-digital-code-p9886546',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'VPNs',
      //       displayName: 'VPNs',
      //       idSub: 131,
      //       children: [
      //         {
      //           name: '[pdp]avast-secureline-vpn-2022-10-devices-1-year-digital-code-p9886549',
      //           displayName: 'Avast SecureLine VPN',
      //           url: '/avast-secureline-vpn-2022-10-devices-1-year-digital-code-p9886549',
      //           queryStr: '',
      //           idSubChild: 1355,
      //         },
      //         {
      //           name: '[pdp]avg-secure-vpn-pc-android-mac-ios-10-devices-3-years-digital-code-p9886566',
      //           displayName: 'AVG Secure VPN',
      //           url: '/avg-secure-vpn-pc-android-mac-ios-10-devices-3-years-digital-code-p9886566',
      //           queryStr: '',
      //           idSubChild: 1356,
      //         },
      //         {
      //           name: '[pdp]bitdefender-premium-vpn-pc-10-devices-1-year-digital-code-p9888316',
      //           displayName: 'Bitdefender Premium VPN',
      //           url: '/bitdefender-premium-vpn-pc-10-devices-1-year-digital-code-p9888316',
      //           queryStr: '',
      //           idSubChild: 1357,
      //         },
      //         {
      //           name: '[pdp]hma-pro-vpn-pc-5-devices-2-years-digital-code-p9888526',
      //           displayName: 'HMA! Pro VPN',
      //           url: '/hma-pro-vpn-pc-5-devices-2-years-digital-code-p9888526',
      //           queryStr: '',
      //           idSubChild: 1358,
      //         },
      //         {
      //           name: '[pdp]f-secure-freedome-vpn-pc-3-devices-1-year-digital-code-p9891043',
      //           displayName: 'F-Secure Freedome VPN',
      //           url: '/f-secure-freedome-vpn-pc-3-devices-1-year-digital-code-p9891043',
      //           queryStr: '',
      //           idSubChild: 1359,
      //         },
      //         {
      //           name: '[pdp]kaspersky-vpn-secure-connection-pc-5-devices-1-year-digital-code-p9891053',
      //           displayName: 'Kaspersky VPN Secure Connection',
      //           url: '/kaspersky-vpn-secure-connection-pc-5-devices-1-year-digital-code-p9891053',
      //           queryStr: '',
      //           idSubChild: 1360,
      //         },
      //         {
      //           name: '[pdp]mcafee-safe-vpn-premium-pc-5-devices-1-year-digital-code-p9890969',
      //           displayName: 'McAfee Safe VPN Premium',
      //           url: '/mcafee-safe-vpn-premium-pc-5-devices-1-year-digital-code-p9890969',
      //           queryStr: '',
      //           idSubChild: 1361,
      //         },
      //         {
      //           name: '[pdp]norton-secure-vpn-us-pc-5-devices-1-year-digital-code-p9891018',
      //           displayName: 'Norton Secure VPN',
      //           url: '/norton-secure-vpn-us-pc-5-devices-1-year-digital-code-p9891018',
      //           queryStr: '',
      //           idSubChild: 1362,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'System Performance',
      //       displayName: 'System Performance',
      //       idSub: 132,
      //       children: [
      //         {
      //           name: '[pdp]exitlag-12-months-software-license-digital-code-p9888290',
      //           displayName: 'ExitLag',
      //           url: '/exitlag-12-months-software-license-digital-code-p9888290',
      //           queryStr: '',
      //           idSubChild: 1363,
      //         },
      //         {
      //           name: '[pdp]3dmark-steam-cd-key-p784330',
      //           displayName: '3DMark',
      //           url: '/3dmark-steam-cd-key-p784330',
      //           queryStr: '',
      //           idSubChild: 1364,
      //         },
      //         {
      //           name: '[pdp]aida64-extreme-pc-1-device-lifetime-key-digital-code-p9890878',
      //           displayName: 'AIDA64 EXTREME',
      //           url: '/aida64-extreme-pc-1-device-lifetime-key-digital-code-p9890878',
      //           queryStr: '',
      //           idSubChild: 1365,
      //         },
      //         {
      //           name: '[pdp]avg-pc-tuneup-2023-unlimited-device-2-years-digital-code-p9887994',
      //           displayName: 'AVG PC TuneUp',
      //           url: '/avg-pc-tuneup-2023-unlimited-device-2-years-digital-code-p9887994',
      //           queryStr: '',
      //           idSubChild: 1366,
      //         },
      //         {
      //           name: '[pdp]ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           displayName: 'CCleaner Professional Plus',
      //           url: '/ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           queryStr: '',
      //           idSubChild: 1367,
      //         },
      //         {
      //           name: '[pdp]avast-cleanup-boost-pro-android-1-device-3-years-digital-code-p9891040',
      //           displayName: 'Avast Cleanup & Boost Pro',
      //           url: '/avast-cleanup-boost-pro-android-1-device-3-years-digital-code-p9891040',
      //           queryStr: '',
      //           idSubChild: 1368,
      //         },
      //         {
      //           name: '[pdp]avast-cleanup-premium-1-device-3-years-digital-code-p9886543',
      //           displayName: 'Avast Cleanup Premium',
      //           url: '/avast-cleanup-premium-1-device-3-years-digital-code-p9886543',
      //           queryStr: '',
      //           idSubChild: 1369,
      //         },
      //         {
      //           name: '[pdp]avg-cleaner-pro-android-1-device-3-years-digital-code-p9891010',
      //           displayName: 'AVG Cleaner Pro',
      //           url: '/avg-cleaner-pro-android-1-device-3-years-digital-code-p9891010',
      //           queryStr: '',
      //           idSubChild: 1370,
      //         },
      //         {
      //           name: '[pdp]iolo-system-mechanic-pc-5-devices-1-year-digital-code-p9888491',
      //           displayName: 'Iolo System Mechanic',
      //           url: '/iolo-system-mechanic-pc-5-devices-1-year-digital-code-p9888491',
      //           queryStr: '',
      //           idSubChild: 1371,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Professional Software',
      //       displayName: 'Editing/Creative Software',
      //       idSub: 133,
      //       children: [
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           displayName: 'AUTODESK AUTOCAD (2023)',
      //           url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           queryStr:
      //             '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           idSubChild: 1372,
      //         },
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //           displayName: 'AUTODESK AUTOCAD (2024)',
      //           url: '/autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //           queryStr: '',
      //           idSubChild: 1373,
      //         },
      //         {
      //           name: '[pdp]wondershare-filmora-12-video-editor-1-device-lifetime-digital-code-p9886467',
      //           displayName: 'Wondershare Filmora 12 Video Editor',
      //           url: '/wondershare-filmora-12-video-editor-1-device-lifetime-digital-code-p9886467',
      //           queryStr: '',
      //           idSubChild: 1374,
      //         },
      //         {
      //           name: '[pdp]nero-platinum-unlimited-pc-1-device-lifetime-key-digital-code-p9891070',
      //           displayName: 'Nero Platinum Unlimited',
      //           url: '/nero-platinum-unlimited-pc-1-device-lifetime-key-digital-code-p9891070',
      //           queryStr: '',
      //           idSubChild: 1375,
      //         },
      //       ],
      //     },
      //   ],
      // },
      //  {
      //   name: 'softwares',
      //   displayName: 'Softwares',
      //   url: '/store?productType[]=software&page=1',
      //   queryStr: 'productType=software',
      //   id: 8,
      // },
      {
        name: 'pre-order',
        displayName: pre_order_msg ?? '',
        url: '/store?preOrder=true&page=1',
        queryStr: 'preOrder=true&limit=50',
        id: 9,
        navbar: true,
      },
      {
        name: 'ea-play',
        displayName: 'EA play',
        url: '/store?productType[]=game&page=1',
        queryStr: 'platform=EA+Play',
        id: 10,
        navbar: false,
      },
      {
        name: 'zomato',
        displayName: 'Zomato',
        url: '/store?platform=Zomato&page=1',
        queryStr: 'platform=Zomato',
        id: 12,
        navbar: false,
      },
      {
        name: 'zalando',
        displayName: 'Zalando',
        url: '/store?platform=Zalando&page=1',
        queryStr: 'platform=Zalando',
        id: 13,
        navbar: false,
      },
      {
        name: 'myntra',
        displayName: 'Myntra',
        url: '/store?platform=Myntra&page=1',
        queryStr: 'platform=Myntra',
        id: 14,
        navbar: false,
      },
      {
        name: 'MakeMyTrip',
        displayName: 'MakeMyTrip',
        url: '/store?platform=MakeMyTrip&page=1',
        queryStr: 'platform=MakeMyTrip',
        id: 15,
        navbar: false,
      },
      {
        name: 'microsoft-store',
        displayName: 'Microsoft Store',
        url: '/store?platform=Microsoft+Store&page=1',
        queryStr: 'platform=Microsoft+Store',
        id: 16,
        navbar: false,
      },

      {
        name: 'nykaa',
        displayName: 'Nykaa',
        url: '/store?platform=Nykaa&page=1',
        queryStr: 'platform=Nykaa',
        id: 17,
        navbar: false,
      },
      {
        name: 'swiggy',
        displayName: 'Swiggy',
        url: '/store?platform=Swiggy&page=1',
        queryStr: 'platform=Swiggy',
        id: 18,
        navbar: false,
      },

      //  {
      //   name: 'other',
      //   displayName: 'Other',
      //   url: '/store?platform=Other&productType=game&page=1',
      //   queryStr: 'platform=Other&productType=game',
      //   id: 15,
      //   navbar:false
      // },
      {
        name: 'steal deals',
        displayName: steal_deals_msg ?? '',
        url: '/store/collection/steal-deals',
        queryStr: '/store',
        id: 19,
        navbar: true,
      },
      {
        name: 'gog-com',
        displayName: gog_com_msg ?? '',
        url: '/store?page=1&platform[]=GOG+COM',
        queryStr: 'platform=GOG+COM&page=1',
        id: 20,
        navbar: false,
      },
      {
        name: 'ubisoft-connect',
        displayName: 'Ubisoft Connect',
        url: '/store?page=1&platform[]=Ubisoft+Connect',
        queryStr: 'platform=Ubisoft+Connect&page=1',
        id: 21,
        navbar: false,
      },
      {
        name: 'roblox',
        displayName: 'Roblox',
        url: '/store?page=1&platform[]=Roblox',
        queryStr: 'platform=Roblox&page=1',
        id: 22,
        navbar: false,
      },
      {
        name: 'mojang',
        displayName: 'Mojang',
        url: '/store?page=1&platform[]=Mojang',
        queryStr: 'platform=Mojang&page=1',
        id: 23,
        navbar: false,
      },
      {
        name: 'ncsoft',
        displayName: 'Ncsoft',
        url: '/store?page=1&platform[]=NCSoft',
        queryStr: 'platform=NCSoft&page=1',
        id: 24,
        navbar: false,
      },
      {
        name: 'save with plus',
        displayName: plus_save_with_plus_msg ?? '',
        url: '/plus',
        queryStr: '',
        id: 25,
        navbar: true,
      },
    ],
  };
};
