import { LanguageItemInterface } from '../Interface/LocalesInterface';

export let languageList: Array<LanguageItemInterface> = [
  {
    id: 1,
    name: 'English',
    locale: 'en',
  },
  {
    id: 2,
    name: 'Español',
    locale: 'es',
  },
  {
    id: 3,
    name: 'Deutsch',
    locale: 'de',
  },
  {
    id: 4,
    name: 'Italiano',
    locale: 'it',
  },
  {
    id: 5,
    name: 'Nederlands',
    locale: 'nl',
  },
  {
    id: 6,
    name: 'Français',
    locale: 'fr',
  },
  {
    id: 7,
    name: 'Magyar',
    locale: 'hu',
  },
  {
    id: 8,
    name: 'Polski',
    locale: 'pl',
  },
  {
    id: 9,
    name: 'Dansk',
    locale: 'da',
  },
  {
    id: 10,
    name: 'Português',
    locale: 'pt',
  },
  {
    id: 11,
    name: 'Suomalainen',
    locale: 'fi',
  },
  {
    id: 12,
    name: 'Svenska',
    locale: 'sv',
  },
  {
    id: 13,
    name: 'Brasileiro',
    locale: 'br',
  },
  {
    id: 14,
    name: 'bahasa Indonesia',
    locale: 'id',
  },
  {
    id: 15,
    name: 'แบบไทย',
    locale: 'th',
  },
  {
    id: 16,
    name: 'Tiếng Việt',
    locale: 'vi',
  },
  {
    id: 17,
    name: 'čeština',
    locale: 'cs',
  },
  {
    id: 18,
    name: 'Ελληνικά',
    locale: 'el',
  },
  {
    id: 19,
    name: 'Filipino',
    locale: 'fil',
  },
  {
    id: 20,
    name: 'Română',
    locale: 'ro',
  },
  {
    id: 21,
    name: 'Русский',
    locale: 'ru',
  },
  {
    id: 22,
    name: 'турецкий',
    locale: 'tr',
  },
  {
    id: 23,
    name: 'Lietuvių',
    locale: 'lt',
  },
  {
    id: 24,
    name: '简体中文',
    locale: 'cn',
  },
];
