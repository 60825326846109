import router from 'next/router';
import React, { memo, useState } from 'react';

import Link from 'next/dist/client/link';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENTS_SUBHEADER_OPTION_CLICKED } from '../../utils/we';
import {
  ChildItemContainer,
  ChildItemOption,
  ChildrenItemTitle,
  ChildrenItemWrapper,
} from './OptionsSectionStyle';
import { OptionsDataI } from './modals';

interface OptionsSectionPropsI {
  data: OptionsDataI;
  display?: number | null;
  index?: number;
  isShowing: boolean;
  onClose: () => void;
  parentSubMenu: string;
}

const OptionsSectionsV2: React.FC<OptionsSectionPropsI> = ({
  data,
  display,
  index,
  isShowing,
  onClose,
  parentSubMenu,
}) => {
  const { cleverTap } = useAnalytics();
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [hovering, setHovering] = useState<Boolean>(false);

  return (
    <ChildrenItemWrapper
      style={{ display: display === index ? 'block' : 'none' }}
    >
      <ChildrenItemTitle>{data.name}</ChildrenItemTitle>
      <ChildItemContainer
      //   count={data?.children.length}>
      >
        {data?.children
          ?.filter((data: any) => data.navbar)
          ?.map((item: any, i: number) => {
            return (
              <Link
                passHref
                // href={item.url ? '/store/' + item.name : ''}
                href={
                  item.name.includes('[pdp]')
                    ? '/' + item.name.replace(/\[pdp\]/, '')
                    : '/store/' + item.name
                }
                key={i}
              >
                <a>
                  <ChildItemOption
                    onMouseEnter={() => {
                      setHovering(true);
                      setHoveredIndex(i);
                    }}
                    onMouseLeave={() => {
                      setHovering(false);
                      setHoveredIndex(-1);
                    }}
                    style={
                      item.displayName === 'View All' ? { color: '#fff' } : {}
                      // {}
                    }
                    onClick={
                      item.url
                        ? () => {
                            WE_USER_EVENTS_SUBHEADER_OPTION_CLICKED(
                              {
                                link: item.name.includes('[pdp]')
                                  ? '/' + item.name.replace(/\[pdp\]/, '')
                                  : '/store/' + item.name,
                                option: item.displayName,
                                pageUrl: window.location.href,
                              },
                              cleverTap
                            );
                            saveEventV3({
                              category: 'header',
                              action: 'click',
                              label: 'navigation_option',
                              properties: item.displayName,
                              value: [
                                item.name.includes('[pdp]')
                                  ? '/' + item.name.replace(/\[pdp\]/, '')
                                  : '/store/' + item.name,
                              ],
                              jsonData: {
                                parent: parentSubMenu,
                                child: item.displayName,
                              },
                              from: router,
                            });
                            onClose();
                          }
                        : () => {
                            onClose();
                          }
                    }
                  >
                    {item.displayName}
                    {/* <OptionArrow
                      isHovered={i === hoveredIndex}
                      isShown={isShowing}
                      isHovering={hovering}
                    >
                      <MaskIcon
                        url="/icons/arrow_right_alt.svg"
                        height="24px"
                        width="24px"
                        margin="0 0 0 0"
                        selected
                        color="#fff"
                      />
                    </OptionArrow> */}
                  </ChildItemOption>
                </a>
              </Link>
            );
          })
          .splice(0, 11)}
        {/* {data?.children.length >= 12 && (
          <Link passHref href="/store">
            <a>
              <ChildItemOption
                key={'loadMore'}
                // onClick={() => router.push('/store')}
              >
                All
              </ChildItemOption>
            </a>
          </Link>
        )} */}
      </ChildItemContainer>
    </ChildrenItemWrapper>
  );
};

export default memo(OptionsSectionsV2);
