import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 64px;
  border-bottom: 1px solid #4b4b4b;
  padding: 0 24px;
  width: 100%;
`;

export const SubChildHeader = styled.div`
  display: flex;
  gap: 12px;
  min-height: 84px;
  display: flex;
  align-items: center;
  font: 16px/18px Onest-Medium;
  color: #fff;
  > p {
    margin-top: -2px;
  }
`;
