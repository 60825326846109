import React from 'react';
import { DrawerOption } from './NavDrawerStyle';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTheme } from 'styled-components';
interface Props {
  item: any;
  indexSubChild: number;
  indexSub?: number;
  onDrawerClick?: (index: any) => void;
  lastSelected?: number;
  lastSelectedChild?: any;
  onClose: any;
  onCloseAll: any;
}

const DrawerSubOptionContainer = ({
  item,
  indexSubChild,
  indexSub,
  onDrawerClick,
  lastSelected,
  lastSelectedChild,
  onClose,
  onCloseAll,
}: Props) => {
  const router = useRouter();
  const theme = useTheme();
  return (
    <>
      <DrawerOption
        style={{
          cursor: 'pointer',
          padding: '3px',
          borderRadius: '8px',
          display: indexSub === lastSelectedChild?.idSub ? 'flex' : 'none',
        }}
        key={indexSubChild}
        onClick={() => onDrawerClick && onDrawerClick(item)}
      >
        <Link
          passHref
          href={item.url ? '/store/' + (item.name ?? '') : '/'}
          prefetch={false}
        >
          <a onClick={() => onCloseAll()}>
            <p style={{ color: theme.palette.text.t3, minWidth: '200px' }}>
              {item.displayName}
            </p>
          </a>
        </Link>
      </DrawerOption>
    </>
  );
};

export default DrawerSubOptionContainer;
