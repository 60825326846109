import Link from 'next/link';
import { generateNavigation } from '../../constants/navItems';

import { memo, useMemo } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';

const NavBarStatic = () => {
  let { messages } = useLocale();

  // Memoize the navigation items to avoid recomputation on every render
  const navigationItems = useMemo(
    () => generateNavigation(messages),
    [messages]
  );

  return (
    <>
      <ul>
        {navigationItems?.children?.map((items: any, index: number) => {
          return (
            <li key={index}>
              <Link
                passHref
                href={
                  items.url && items.name !== 'store'
                    ? '/store/' + (items.name ?? '')
                    : items.name === 'store'
                    ? '/store'
                    : '/'
                }
                prefetch={false}
              >
                <a>{items.displayName}</a>
              </Link>
              <ul>
                {items?.subDivision &&
                  items?.subDivision?.map((subChild: any, ind: number) => {
                    return (
                      <li key={ind}>
                        {subChild.displayName}
                        <ul>
                          {subChild?.children &&
                            subChild?.children?.map(
                              (subChildChildren: any, inx: number) => {
                                return (
                                  <li key={inx}>
                                    <Link
                                      passHref
                                      href={
                                        subChildChildren.url
                                          ? '/store/' +
                                            (subChildChildren.name ?? '')
                                          : '/'
                                      }
                                      prefetch={false}
                                    >
                                      <a>{subChildChildren.displayName}</a>
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default memo(NavBarStatic);
