import { memo, useCallback, useRef, useState } from 'react';
import NavBarStatic from '../NavDrawer/NavBarStatic';
import NavDrawer from '../NavDrawer/NavDrawer';
import { SwipeableDrawerContainer } from '../NavDrawer/NavDrawerStyle';

interface Props {
  toggleDrawer: () => void;
  drawerState: boolean;
  setDrawerStatus: any;
  onClickOutside: any;
}

const MUIDrawer = ({
  toggleDrawer,
  drawerState,
  setDrawerStatus,
  onClickOutside,
}: Props) => {
  const ref = useRef(null);
  const [toggleSubDrawer, setToggleSubDrawer] = useState<boolean | false>(
    false
  );
  const [toggleSubDrawerChildren, setToggleSubDrawerChildren] = useState<
    boolean | false
  >(false);
  const [lastedSelected, setLastedSelected] = useState<any | null>(null);
  const [lastedSelectedChild, setLastedSelectedChild] = useState<any | null>(
    null
  );
  const [closeAll, setCloseAll] = useState<boolean>(false);

  const handleSubDrawer = useCallback(
    (index?) => {
      setLastedSelected(index);
      setToggleSubDrawer(!toggleSubDrawer);
    },
    [toggleSubDrawer]
  );

  const handleSubDrawerChild = useCallback(
    (index?) => {
      setLastedSelectedChild(index);
      setToggleSubDrawerChildren(!toggleSubDrawerChildren);
    },
    [toggleSubDrawerChildren]
  );

  const handleCloseAll = useCallback(() => {
    setDrawerStatus(false);
    setToggleSubDrawer(false);
    setToggleSubDrawerChildren(false);
  }, [setDrawerStatus]);

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (ref.current && !ref.current?.contains(event.target)) {
  //       onClickOutside && onClickOutside();
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [onClickOutside]);

  return (
    <>
      <SwipeableDrawerContainer
        style={{
          zIndex: 2001,
          left: drawerState ? '-1500px' : '-1500px',
        }}
      >
        <NavBarStatic />
      </SwipeableDrawerContainer>
      <SwipeableDrawerContainer
        ref={ref}
        style={{
          zIndex: 2000,
          left: drawerState ? 0 : '-1500px',
        }}
      >
        <NavDrawer
          onClose={toggleDrawer}
          onDrawerClick={handleSubDrawer}
          onCloseAll={handleCloseAll}
        />
      </SwipeableDrawerContainer>
      {/* <SwipeableDrawer
        anchor="left"
        onClose={handleSubDrawer}
        onOpen={handleSubDrawer}
        open={toggleSubDrawer}
        disableBackdropTransition={true}
        disableDiscovery={true}
        PaperProps={{ style: { width: '100%' } }}
        style={{
          width: '100%',
          zIndex: toggleSubDrawer ? 2001 : 1999,
        }}
      >
        <NavSubDrawer
          onClose={handleSubDrawer}
          subChild={true}
          lastedSelected={lastedSelected}
          onDrawerClick={handleSubDrawerChild}
          onCloseAll={handleCloseAll}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="left"
        onClose={handleSubDrawerChild}
        onOpen={handleSubDrawerChild}
        open={toggleSubDrawerChildren}
        disableBackdropTransition={true}
        disableDiscovery={true}
        PaperProps={{ style: { width: '100%' } }}
        style={{
          width: '100%',
          zIndex: toggleSubDrawerChildren ? 2002 : 1998,
          display: toggleSubDrawerChildren && drawerState ? 'block' : 'none',
        }}
      >
        <NavSubDrawerChildren
          onClose={handleSubDrawerChild}
          subChild={true}
          lastedSelected={lastedSelected}
          onCloseAll={handleCloseAll}
          lastSelectedChild={lastedSelectedChild}
        />
      </SwipeableDrawer> */}
    </>
  );
};

export default memo(MUIDrawer);
