import styled, { css, keyframes } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const ChildrenItemWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  flex-wrap: wrap;
  // min-width: 280px;
  // padding: 12px;
  border-radius: 12px;
  // background: linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
  // :hover {
  //   // color: ${(props) => props.theme.palette.primary.main};
  //   // background: ${(props) => props.theme.palette.background.bg1};
  //   background: #212121;
  // }
  // @media (max-width: ${WIDTH.laptopMax}) {
  //   min-width: 320px;
  // }
`;

export const ChildrenItemTitle = styled.div`
  color: #416aff;
  width: max-content;
  font-size: 16px;
  line-height: 20px;
  font-family: Onest-Bold;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 0px;
  margin-left: 0px;
  text-transform: uppercase;
  letter-spacing: 0.64px;
  @media (max-width: ${WIDTH.laptopMax}) {
    font-size: 14px;
  }
`;

export const ChildItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const hoverAnimation = keyframes`
  from {
    
    opacity:0;
    // transform: translateX(0);
    
  }
  to {
    opacity:1;
    // transform: translateX(-20px);
    
  }
`;

const hoverOutAnimation = keyframes`
  from {
    opacity:1;
    // transform: translateX(-20px);
    
  }
  to {
    opacity:0;
    // transform: translateX(0);
  }
`;

export const ChildItemOption = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8f8f8f;
  // cursor: pointer;
  font-size: 14px;
  // padding: 10px;
  border-radius: 8px;
  width: 100%;
  gap: 5px;
  align-items: center;
  position: relative;
  transition: color 0.3s ease;
  :hover {
    // background: #303030;
    color: #fff;
  }
  // @media (max-width: ${WIDTH.laptopMax}) {
  //   font-size: 14px;
  //   padding: 8px;
  // }
`;

export const OptionArrow = styled.div<{
  isHovered: Boolean;
  isShown: Boolean;
  isHovering: Boolean;
}>`
  // position: absolute;
  right: 0;
  opacity: 0;

  ${(props) =>
    props.isHovered &&
    css`
      animation: ${hoverAnimation} 0.2s ease-in-out forwards;
    `}
  ${(props) =>
    props.isHovered &&
    !props.isHovering &&
    css`
      animation: ${hoverOutAnimation} 0.2s ease-in-out forwards;
    `}
`;
