import React from 'react';
import { DrawerOption } from './NavDrawerStyle';
import { useRouter } from 'next/router';
import { IconButton } from '../CurrentlySellingDetails/CurrentlySellingDetailsStyle';
import { MaskIcon } from '../Icons/Icons';
import { useTheme } from 'styled-components';

interface Props {
  item: any;
  indexSubChild: number;
  indexSub?: number;
  onDrawerClick?: (index: any) => void;
  lastSelected?: any;
  onClose: any;
  onCloseAll: any;
}

const DrawerOptionContainer = ({
  item,
  indexSubChild,
  indexSub,
  onDrawerClick,
  lastSelected,
  onClose,
  onCloseAll,
}: Props) => {
  const router = useRouter();
  const theme = useTheme();
  return (
    <>
      <DrawerOption
        style={{
          cursor: 'pointer',
          padding: '3px',
          borderRadius: '8px',
          marginBottom: '24px',
          justifyContent: 'space-between',
          display: indexSub === lastSelected?.id ? 'flex' : 'none',
        }}
        key={indexSubChild}
      >
        {/* <Link
          passHref
          href={item.url ? '/store/' + (item.name ?? '') : '/'}
          prefetch={false}
        >
          <a> */}
        <p
          style={{ color: theme.palette.text.t3, minWidth: '200px' }}
          // onClick={() => onCloseAll()}
        >
          {item.displayName}
        </p>
        {/* </a>
        </Link> */}
        {item.children && (
          <IconButton
            style={{ margin: '0 -12px 0 -12px' }}
            onClick={(e) => {
              e.stopPropagation();
              onDrawerClick && onDrawerClick(item);
            }}
          >
            <MaskIcon
              url="/icons/right-arrow-black.svg"
              width="12px"
              height="12px"
              selected={true}
              margin="0"
              color="#fff"
            />
          </IconButton>
        )}
      </DrawerOption>
    </>
  );
};

export default DrawerOptionContainer;
