import { memo } from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../../components/Icons/Icons';
import {
  NoticeAlertContainer,
  NoticeAlertWrapper,
} from './SellerNoticeAlertStyle';

function SellerNoticeAlert() {
  const theme = useTheme();

  return (
    <NoticeAlertWrapper>
      <NoticeAlertContainer>
        <div>
          <MaskIcon
            url="/icons/info-24.svg"
            height="24px"
            width="24px"
            selected
            color={theme.palette.error.dark}
          />
        </div>
        <p>
          Your store has limited access! You can still access sales and withdraw
          your funds.
        </p>
      </NoticeAlertContainer>
    </NoticeAlertWrapper>
  );
}

export default memo(SellerNoticeAlert);
