import styled from 'styled-components';
import { WIDTH } from '../constants/screenResolution';

// GLOBAL WINDOW - GW
export const GWWebWrapper = styled.div`
  display: block;
  @media(max-width: ${WIDTH.tabletMax}) {
    display: none;
`;

export const GWOnlyDesktopWrapper = styled.div`
display: block;
  @media(max-width: ${WIDTH.laptopMax}) {
    display: none;
`;

export const GWTabletWrapper = styled.div`
  display: block;
  @media(max-width: ${WIDTH.mobileMax}) {
    display: none;
`;

export const GWMobileWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
  }
`;

export const GWOnlyTabletWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
  }
`;

export const GWLaptopWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.laptopMax}) {
    display: block;
  }
`;

export const GWOnlyMobileWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: block;
  }
`;
