import { IconButton } from '@mui/material';
import BrandLogo from '../../BrandLogo/BrandLogo';
import { MaskIcon } from '../../Icons/Icons';
import { HeaderContainer, SubChildHeader } from './DrawerHeaderStyle';
import { useRouter } from 'next/router';

interface Props {
  onClose: () => void;
  onCloseAll?: () => void;
  subChild?: boolean;
  lastedSelected?: any;
  lastSelectedChild?: any;
}

export default function DrawerHeader({
  onClose,
  subChild,
  onCloseAll,
  lastedSelected,
  lastSelectedChild,
}: Props) {
  const router = useRouter();
  return (
    <HeaderContainer>
      {router.pathname.substring(5, 14) === '/selling/' || !subChild ? (
        <>
          <BrandLogo />
          <IconButton style={{ margin: '0 -16px 0 0' }} onClick={onCloseAll}>
            <MaskIcon
              url="/icons/close-24.svg"
              width="24px"
              height="24px"
              selected={true}
              margin="0"
              color="#fff"
            />
          </IconButton>
        </>
      ) : (
        <>
          <SubChildHeader>
            <IconButton style={{ margin: '0 -12px 0 -12px' }} onClick={onClose}>
              <MaskIcon
                url="/icons/left-arrow-black.svg"
                width="12px"
                height="12px"
                selected={true}
                margin="0"
                color="#fff"
              />
            </IconButton>
            <p>
              {lastSelectedChild
                ? lastSelectedChild?.displayName
                : lastedSelected
                ? lastedSelected?.displayName
                : undefined}
            </p>
          </SubChildHeader>
          <IconButton
            style={{ margin: '0 -16px 0 0' }}
            onClick={() => onCloseAll && onCloseAll()}
          >
            <MaskIcon
              url="/icons/close-24.svg"
              width="24px"
              height="24px"
              margin="0"
            />
          </IconButton>
        </>
      )}
    </HeaderContainer>
  );
}
