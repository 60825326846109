import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AvatarRound from '../../../components/Avatar/AvatarRound';
import { MaskIcon } from '../../../components/Icons/Icons';
import { COLORS } from '../../../constants/colors';
import { ManagerSubOptionI } from './SideNavigation';
import {
  NavigationItem,
  NavigationTile,
  NavigationTileHeader,
  NavigationTileTitle,
  SubOption,
  SubOptionsContainer,
} from './SideNavvigationStyles';

interface Props {
  store: any;
  selectedHeaderOption: string;
  showManagerSubOption: ManagerSubOptionI;
  handleManagerSubOptionClick: any;
}

export const ManagerStoreNavComp = (props: Props) => {
  const router = useRouter();
  return (
    <NavigationItem>
      <Link href={`${`/user/selling-accounts/tickets`}`}>
        <a>
          <NavigationTile
            active={
              props.selectedHeaderOption === 'seller' ||
              props.showManagerSubOption.show
            }
          >
            <NavigationTileHeader>
              {props.store?.store_icon && props.store?.store_name ? (
                <Image
                  src={props.store?.store_icon}
                  alt={props.store?.store_name ?? ''}
                  height={'24px'}
                  width={'24px'}
                  style={{ borderRadius: '50%' }}
                  objectFit="cover"
                  objectPosition="center"
                  unoptimized
                  priority={false}
                />
              ) : (
                <AvatarRound
                  name={props.store?.store_name?.trim() || 'D'}
                  variant="circular"
                  height="24px"
                  width="24px"
                />
              )}

              <NavigationTileTitle>
                {props.store.store_name}
              </NavigationTileTitle>
            </NavigationTileHeader>
            <MaskIcon
              url="/icons/arrow-down-chevron-24.svg"
              width="24px"
              height="24px"
              selected={true}
              margin={'0 0 0 auto'}
              color={COLORS.white}
              onClick={(e: any) =>
                props.handleManagerSubOptionClick(e, props.store.store_id)
              }
              style={{
                zIndex: 10,
                transform: props.showManagerSubOption.show
                  ? 'rotate(180deg)'
                  : 'rotate(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </NavigationTile>
        </a>
      </Link>
      {props.showManagerSubOption.show && (
        <SubOptionsContainer>
          {!!props.store.permissions?.includes('tickets') && (
            <Link href={`/user/selling-accounts/tickets`} prefetch={false}>
              <a>
                <SubOption
                  active={router.pathname.includes(
                    '/user/selling-accounts/tickets'
                  )}
                >
                  Tickets
                </SubOption>
              </a>
            </Link>
          )}
          {!!props.store.permissions?.includes('ticket_logs') && (
            <Link href={`/user/selling-accounts/ticket-logs`} prefetch={false}>
              <a>
                <SubOption
                  active={
                    router.pathname === '/user/selling-accounts/ticket-logs'
                  }
                >
                  Ticket Logs
                </SubOption>
              </a>
            </Link>
          )}
        </SubOptionsContainer>
      )}
    </NavigationItem>
  );
};
